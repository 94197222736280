import React, { useMemo, useState } from 'react';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useFormContext } from 'react-hook-form';

import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import { externalUsers } from '../../../Services/Draft';
import { removeAnnotation } from '../Helper';

interface Props {
  signatoriesData: any[];
  userTypes: any[];
  user_type: any;
  onSubmit: (data: any) => void;
  handleUserType: VoidFunction;
  control: any;
  handleSubmit: any;
  instance: any;
  externalUsersData: any;
  isLoading: boolean;
}

const AddSignatories: React.FC<Props> = ({
  signatoriesData,
  userTypes,
  user_type,
  onSubmit,
  handleUserType,
  control,
  handleSubmit,
  instance,
  externalUsersData,
  isLoading,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { watch, reset } = useFormContext();
  const [showExternalFields, setShowExternalFields] = useState(false);
  const name = watch('name') || '';
  const email = watch('email') || '';
  const selectExternalUser = watch('select_external_user' || '');

  const handleClear = async () => {
    handleUserType();
    reset();
    await removeAnnotation(
      instance,
      user_type === 'external' ? selectExternalUser : ''
    );
  };

  const { mutate: externalUsersMutation } = useMutation({
    mutationKey: ['external_users'],
    mutationFn: externalUsers,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['external_users_list'],
      });
      enqueueSnackbar('External fields saved successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });

      setShowExternalFields(false);
    },
    onError: () => {
      enqueueSnackbar('Failed to save external fields!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleAddNewClick = () => {
    setShowExternalFields((prev) => !prev);
  };

  const handleNewFieldsSave = () => {
    const payload = [
      {
        user_name: name,
        email: email,
      },
    ];
    externalUsersMutation(payload);
  };

  const sortedExternalUsers = useMemo(() => {
    return externalUsersData?.sort((a: any, b: any) =>
      a?.username.localeCompare(b?.username)
    );
  }, [externalUsersData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={1}
        sx={{ backgroundColor: '#88305f1f' }}
        borderRadius="10px"
        padding={2}
        marginBottom={2}
      >
        <Typography fontSize="16px" fontWeight="bold">
          Add signatories
        </Typography>

        <RadioButtonGroup
          name="user_type"
          control={control}
          row
          required
          options={userTypes}
          valueKey="value"
          onChange={handleUserType}
        />

        {user_type === 'external' && (
          <Stack spacing={2}>
            <RISelectComponent
              label="Select External Signatory"
              name="select_external_user"
              control={control}
              options={sortedExternalUsers}
              required
            />
            <Button
              sx={{
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                gap: '6px',
              }}
              onClick={handleAddNewClick}
            >
              <AddCircleOutlineRoundedIcon fontSize="small" /> Add New
            </Button>
            {showExternalFields && (
              <Stack spacing={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <ControlledTextField
                    name="name"
                    control={control}
                    label="Name"
                    fullWidth
                    required
                  />
                  <ControlledTextField
                    name="email"
                    control={control}
                    label="Email Address"
                    fullWidth
                    type="email"
                    required
                  />
                </Stack>
                <Button
                  variant="outlined"
                  sx={{ width: 'fit-content', alignSelf: 'end' }}
                  onClick={handleNewFieldsSave}
                >
                  Save
                </Button>
              </Stack>
            )}
          </Stack>
        )}

        {user_type === 'internal' && (
          <RISelectComponent
            name="name"
            control={control}
            label="Select Signatory"
            options={signatoriesData}
            required
          />
        )}
        <Stack justifyContent="end" direction="row" paddingTop="15px">
          <LoadingButton loading={isLoading} variant="contained" type="submit">
            Add signatory
          </LoadingButton>
          <Button onClick={handleClear} variant="outlined">
            Clear
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default AddSignatories;
