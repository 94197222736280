import React, { Dispatch, SetStateAction, useState } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EventIcon from '@mui/icons-material/Event';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import TabIcon from '@mui/icons-material/Tab';
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import SupportDocsDrawer from './SupportDocsDrawer';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';

interface Props {
  setOpenUploadDialog: Dispatch<SetStateAction<any>>;
  handleOpenDeleteDialog: (id: string, data: any) => void;
  handleOpenAssigneeDialog: (data: any) => void;
  handleOpenUploadDialog: (id: string) => void;
  handleActiveStatus: (checkedValue: boolean, data: any) => void;
  checklistData: string[];
  isExternal?: boolean;
  userIsOwner: boolean;
  updateReminder?: any;
}

const Checklist: React.FC<Props> = ({
  handleOpenDeleteDialog,
  handleOpenAssigneeDialog,
  handleOpenUploadDialog,
  handleActiveStatus,
  checklistData,
  isExternal,
  userIsOwner,
  updateReminder,
}) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [supportDocList, setSupportDocList] = useState<any[]>([]);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setSupportDocList([]);
  };

  const handleCheckboxStatus = (
    reminder_id: string,
    checked: boolean,
    support_doc: any[]
  ) => {
    if (reminder_id) {
      const status = checked
        ? 'Completed'
        : support_doc && support_doc?.length > 0
          ? 'In Progress'
          : 'Not Started';

      const updateChecklistPayload = {
        id: reminder_id,
        body: {
          status: status,
        },
      };
      updateReminder(updateChecklistPayload);
    }
  };

  return (
    <React.Fragment>
      <List sx={{ width: '100%', bgcolor: 'transparent' }}>
        {checklistData?.map((value: any) => {
          const fullName: string[] = value?.user_name?.split(' ');
          return (
            !value.deleted_status && (
              <ListItem
                key={value}
                secondaryAction={
                  <Stack direction="row" alignItems="center">
                    {value?.support_doc?.length > 0 && (
                      <Stack direction="row" alignItems="center">
                        <Typography fontSize="12px">
                          {value?.support_doc?.length} attachment
                        </Typography>
                        <IconButton
                          edge="end"
                          onClick={() => handleOpenUploadDialog(value)}
                          disabled={value?.active_status}
                        >
                          <AttachFileIcon
                            style={{
                              rotate: '90deg',
                            }}
                          />
                        </IconButton>
                        <Tooltip title="Show connected documents">
                          <IconButton
                            edge="end"
                            sx={{ ml: 1 }}
                            onClick={() => {
                              setOpenDrawer(true);
                              setSupportDocList(value?.support_doc);
                            }}
                          >
                            <TabIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    )}
                    {value?.support_doc?.length === 0 && (
                      <IconButton
                        edge="end"
                        onClick={() => handleOpenUploadDialog(value)}
                        disabled={value?.active_status}
                      >
                        <FileUploadOutlinedIcon />
                      </IconButton>
                    )}
                    {!isExternal && (
                      <>
                        <IconButton
                          edge="end"
                          disabled={userIsOwner}
                          sx={{ ml: 1 }}
                          onClick={() => {
                            handleOpenDeleteDialog(value?.id, value);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                        {!value?.user_name && (
                          <IconButton
                            edge="end"
                            sx={{ ml: 1 }}
                            disabled={userIsOwner || value?.active_status}
                            onClick={() => handleOpenAssigneeDialog(value)}
                          >
                            <PersonAddAltOutlinedIcon />
                          </IconButton>
                        )}
                      </>
                    )}

                    {value?.user_name && (
                      <Stack direction="row" alignItems="center">
                        <IconButton
                          edge="end"
                          sx={{ ml: 1 }}
                          disabled={userIsOwner || value?.active_status}
                          onClick={() =>
                            isExternal ? {} : handleOpenAssigneeDialog(value)
                          }
                        >
                          <NameAvatar
                            firstName={fullName?.[0]}
                            lastName={fullName?.[1]}
                          />
                        </IconButton>
                        {!value?.reminder?.due_date && (
                          <Tooltip title="Create Reminder">
                            <IconButton
                              disabled={userIsOwner || value?.active_status}
                            >
                              <EventIcon
                                onClick={() =>
                                  isExternal
                                    ? {}
                                    : handleOpenAssigneeDialog(value)
                                }
                                sx={{
                                  fill: '#88305F',
                                  fontSize: '20px',
                                  marginLeft: '10px',
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    )}
                  </Stack>
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Checkbox
                      disabled={userIsOwner}
                      edge="start"
                      defaultChecked={value?.active_status}
                      onChange={(event: any) => {
                        handleActiveStatus(event.target.checked, value);
                        if (value?.reminder?.id) {
                          handleCheckboxStatus(
                            value?.reminder?.id,
                            event.target.checked,
                            value?.support_doc
                          );
                        }
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title={value?.checklist_name}>
                        <Box
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '110px',
                          }}
                        >
                          {value?.checklist_name}
                        </Box>
                      </Tooltip>
                    }
                  />
                </ListItemButton>
              </ListItem>
            )
          );
        })}
      </List>
      {openDrawer && (
        <SupportDocsDrawer
          open={openDrawer}
          onClose={handleCloseDrawer}
          supportDocs={supportDocList}
          isExternal={isExternal}
        />
      )}
    </React.Fragment>
  );
};

export default Checklist;
