import React from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';

import CustomModal from './CustomModal';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  onConfirm?: VoidFunction;
  title: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  renderAction?: React.ReactNode;
  children: React.ReactNode;
  buttonLoading?: boolean;
}

const ReusableConfirmationModal: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  title,
  confirmBtnText,
  cancelBtnText,
  renderAction,
  children,
  buttonLoading,
}) => {
  return (
    <CustomModal title={title} open={open} handleClose={onClose}>
      <Stack direction="column" spacing={2} margin="15px">
        {children}

        {renderAction ? (
          renderAction
        ) : (
          <Stack direction="row" spacing={1}>
            <LoadingButton
              loading={buttonLoading}
              onClick={onConfirm}
              variant="contained"
              style={{ padding: '5px 15px' }}
            >
              {confirmBtnText}
            </LoadingButton>
            <Button
              onClick={onClose}
              variant="outlined"
              style={{ padding: '5px 15px' }}
            >
              {cancelBtnText}
            </Button>
          </Stack>
        )}
      </Stack>
    </CustomModal>
  );
};

export default ReusableConfirmationModal;
