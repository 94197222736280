import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridSortModel,
} from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useDebouncedCallback } from 'use-debounce';

import { IObject } from '../../../interfaces/IObject';
import DataGridTable from '../../../RiverusUI/DataGrid/DataGridTable';
import { GridCellExpand } from '../../../RiverusUI/DataGrid/GridCellExpand';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import TableAsyncSelectInput from '../../../RiverusUI/DataGrid/TableAsyncSelectInput';
import TableChipList from '../../../RiverusUI/DataGrid/TableChipList';
import TableHeaderWithSearch from '../../../RiverusUI/DataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../../../RiverusUI/DataGrid/TableSearchInputComponent';
import TableSearchSelectInput from '../../../RiverusUI/DataGrid/TableSearchSelectInput';
import {
  fetchContractType,
  fetchGroups,
  getUsers,
} from '../../../Services/Approval';
import { QueryKeyGenerator } from '../../../Utils/QueryKeyGenerator';
import CreateApprovalComponent from '../CreateApprovalForm/CreateApprovalComponent';

interface Props {
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  selectedRow: GridRowId[];
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  setSorting?: Dispatch<SetStateAction<GridSortModel>>;
  deleteApproval: (ids: GridRowId[]) => void;
  editApproval: (id: GridRowId) => void;
  copyApproval: (id: GridRowId) => void;
  filters?: IObject;
  userAdminRole: boolean;
}

const ApprovalTable = (props: Props) => {
  const {
    setSelectedRow,
    selectedRow,
    data,
    isLoading,
    pageNumber,
    setPageNumberChange,
    setFilters,
    setSorting,
    deleteApproval,
    editApproval,
    copyApproval,
    filters,
    userAdminRole,
  } = props;

  const isActionDisabled = useMemo<boolean>(
    () => selectedRow.length > 1,
    [selectedRow]
  );

  const [searchApproval, setSearchApproval] = useState<boolean>(false);
  const [searchContract, setSearchContract] = useState<boolean>(false);
  const [searchAuthor, setSearchAuthor] = useState<boolean>(false);
  const [searchGroups, setSearchGroups] = useState<boolean>(false);
  const [openCreateApproval, setOpenCreateApproval] = useState<boolean>(false);
  const [editCopyDetailId, setEditCopyDetailId] = useState<any>();
  const [isViewPolicy, setIsViewPolicy] = useState<boolean>(false);

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumberChange(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const { data: contractData } = useQuery({
    queryKey: QueryKeyGenerator.getChoiceFetchingQuery(),
    queryFn: async () => {
      const response = await fetchContractType();
      return response.results;
    },
  });

  const { data: authorData } = useQuery({
    queryKey: ['author'],
    queryFn: async () => {
      const response = await getUsers();
      const users = response?.users || [];
      for (let i = 0; i < users.length; i++) {
        const name = users[i].first_name + ' ' + users[i].last_name;
        users[i].name = name;
      }
      return users;
    },
  });

  const { data: groupData } = useQuery({
    queryKey: ['groups'],
    queryFn: async () => {
      const response = await fetchGroups();
      return response;
    },
  });

  const handleCloseOfViewPolicy = () => {
    setEditCopyDetailId(null);
    setOpenCreateApproval(false);
  };

  const handleOpenViewPolicy = (id: string) => {
    setEditCopyDetailId(id);
    setOpenCreateApproval(true);
    setIsViewPolicy(true);
  };

  const handleGroupClick = useCallback(
    (groups: any) => {
      handleFilterChange('groups', groups);
      setSearchGroups(true);
    },
    [handleFilterChange]
  );

  const handleContractTypeClick = React.useCallback(
    (contractType: any) => {
      setFilters((prev: IObject) => ({
        ...prev,
        contract: contractType,
      }));
      if (contractType?.length) {
        setPageNumberChange(0);
      }
      setSearchContract(true);
    },
    [setFilters]
  );

  const columns = React.useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'policy_name',
        headerName: 'Approval Name',
        minWidth: 200,
        flex: 1,
        sortable: !searchApproval,
        renderHeader: () => {
          return searchApproval ? (
            <TableSearchInputComponent
              key="approval"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, policy_name: null }));
                setSearchApproval(false);
              }}
              placeholder="Search Name"
              handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
                handleFilterChange('policy_name', e.target.value)
              }
            />
          ) : (
            <TableHeaderWithSearch
              title="Approval Name"
              setIsSearch={setSearchApproval}
            />
          );
        },
        renderCell: (Params) => {
          return (
            <GridCellExpand
              value={Params?.row?.policy_name}
              width={Params?.colDef?.computedWidth}
              onClick={() => handleOpenViewPolicy(Params.row?.id)}
              cellStyle={{
                cursor: 'pointer',
              }}
            />
          );
        },
      },
      {
        field: 'created_by',
        headerName: 'Author',
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return (
            <NameAvatar
              firstName={params.row.created_by.first_name}
              lastName={params.row.created_by.last_name}
            />
          );
        },
        renderHeader: () => {
          return searchAuthor ? (
            <TableSearchInputComponent
              key="author"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, created_by: null }));
                setSearchAuthor(false);
              }}
              placeholder="Search Author"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key="author"
                  label="Select Author"
                  options={authorData}
                  onChange={(value: string[]) =>
                    handleFilterChange('created_by', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Author"
              setIsSearch={setSearchAuthor}
            />
          );
        },
      },
      {
        field: 'groups',
        headerName: 'Prescribing Dept.',
        maxWidth: 300,
        width: 250,
        sortable: false,
        minWidth: 150,
        renderCell: (params) => (
          <TableChipList
            id={`groups-${params?.row?.id}`}
            labelKey="name"
            valueKey="id"
            chipColor="#DCBDE7"
            list={params.row.groups || []}
            title={`${params?.row?.policy_name} group`}
            onClick={handleGroupClick}
          />
        ),
        renderHeader: () => {
          return searchGroups ? (
            <TableSearchInputComponent
              key="groups"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, groups: null }));
                setSearchGroups(false);
              }}
              placeholder="Search Prescribing Dept."
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key="groups"
                  options={groupData}
                  label="Select Prescribing Dept."
                  value={filters?.groups ? filters.groups : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('groups', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Prescribing Dept."
              setIsSearch={setSearchGroups}
            />
          );
        },
      },
      {
        field: 'contract',
        headerName: 'Applicable for',
        minWidth: 240,
        flex: 1,
        sortable: false,
        renderHeader: () => {
          return searchContract ? (
            <TableSearchInputComponent
              key="contract"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, contract: null }));
                setSearchContract(false);
              }}
              placeholder="Search Contract"
              selectedValue={filters?.contract ? filters?.contract : []}
              renderCustomInput={() => (
                <TableSearchSelectInput
                  key="contract"
                  valueKey="id"
                  label="Select Contract"
                  options={contractData}
                  value={filters?.contract ? filters?.contract : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('contract', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Applicable for"
              setIsSearch={setSearchContract}
            />
          );
        },
        renderCell: (params) => (
          <TableChipList
            id={`${params?.row?.id}-contract`}
            labelKey="name"
            list={params?.row?.contract || []}
            chipColor="#C4DBFF"
            valueKey="id"
            title={`${params?.row?.policy_name} applicable for`}
            onClick={handleContractTypeClick}
          />
        ),
      },
      {
        field: 'actions',
        headerName: 'More Actions',
        type: 'actions',
        minWidth: 150,
        getActions: (params) => [
          <GridActionsCellItem
            key="actions"
            icon={<EditOutlinedIcon />}
            label="Edit"
            disabled={isActionDisabled || !userAdminRole}
            onClick={() => {
              editApproval(params.id);
            }}
          />,
          // <GridActionsCellItem
          //   icon={<CopyAllOutlinedIcon />}
          //   label="Copy"
          //   disabled={isActionDisabled}
          //   onClick={() => {
          //     copyApproval(params.id);
          //   }}
          // />,
          <GridActionsCellItem
            key="delete"
            icon={<DeleteOutlineOutlinedIcon />}
            label="Delete"
            disabled={isActionDisabled || !userAdminRole}
            onClick={() => deleteApproval([params.id])}
          />,
        ],
      },
    ],
    [
      authorData,
      contractData,
      copyApproval,
      deleteApproval,
      editApproval,
      groupData,
      handleFilterChange,
      isActionDisabled,
      searchApproval,
      searchAuthor,
      searchContract,
      searchGroups,
      setFilters,
    ]
  );

  return (
    <>
      <DataGridTable
        rows={data?.results || []}
        columns={columns}
        rowCount={data?.count}
        isLoading={isLoading}
        setSelectedRow={setSelectedRow}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        setSorting={setSorting}
        checkboxSelection
        userAdminRole={userAdminRole}
      />
      {openCreateApproval && (
        <CreateApprovalComponent
          open={openCreateApproval}
          onClose={handleCloseOfViewPolicy}
          editDetails={!isViewPolicy}
          editCopyDetailId={editCopyDetailId}
          isViewPolicy={isViewPolicy}
          setIsViewPolicy={setIsViewPolicy}
          userAdminRole={userAdminRole}
        />
      )}
    </>
  );
};

export default ApprovalTable;
