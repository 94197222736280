import React, { Dispatch, SetStateAction, useState } from 'react';

import CallMadeIcon from '@mui/icons-material/CallMade';
import { Box, Button, Chip, IconButton, Stack } from '@mui/material';
import { GridColDef, GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isArray } from 'lodash-es';
import { enqueueSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';

import AssigneeDrawer from './AssigneeDrawer/AssigneeDrawer';
import AvatarList from './AvatarList';
import CheckListComponent from './DraftChecklist/CheckListComponent';
import VersionDrawer from './VersionDrawer/VersionDrawer';
import { getSessionStorage } from '../../Authentication/Actions/authentication';
import { IObject } from '../../interfaces/IObject';
import DrawerComponent from '../../RiverusUI/Components/DrawerComponent';
import OverflowTip from '../../RiverusUI/Components/OverflowTip';
import DataGridTable from '../../RiverusUI/DataGrid/DataGridTable';
import { GridCellExpand } from '../../RiverusUI/DataGrid/GridCellExpand';
import TableAsyncSelectInput from '../../RiverusUI/DataGrid/TableAsyncSelectInput';
import TableChipList from '../../RiverusUI/DataGrid/TableChipList';
import TableHeaderWithSearch from '../../RiverusUI/DataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../../RiverusUI/DataGrid/TableSearchInputComponent';
import TableSearchSelectInput from '../../RiverusUI/DataGrid/TableSearchSelectInput';
import { fetchGroups } from '../../Services/Approval';
import { getContractTypes } from '../../Services/DocumentLibrary';
import { fetchAssignee } from '../../Services/Draft';
import { QueryKeyGenerator } from '../../Utils/QueryKeyGenerator';

interface Props {
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  setSorting?: Dispatch<SetStateAction<GridSortModel>>;
  filters?: IObject;
}

const tableButtonStyle = {
  padding: 0,
  justifyContent: 'start',
  ':hover': { background: 'transparent' },
};

const DraftTable = (props: Props) => {
  const {
    setSelectedRow,
    data,
    isLoading,
    pageNumber,
    setPageNumberChange,
    setFilters,
    setSorting,
    filters,
  } = props;

  const [searchDraft, setSearchDraft] = useState<boolean>(false);
  const [searchContract, setSearchContractType] = useState<boolean>(false);
  const [searchGroups, setSearchGroups] = useState<boolean>(false);
  const [searchStatus, setSearchStatus] = useState<boolean>(false);
  const [searchAssignee, setSearchAssignee] = useState<boolean>(false);
  const [openVersionDrawer, setOpenVersionDrawer] = useState<boolean>(false);
  const [draftRowData, setDraftRowData] = useState<any>({});
  const [openAssigneeDrawer, setOpenAssigneeDrawer] = useState<boolean>(false);
  const handleCloseAssigneeDrawer = () => {
    setOpenAssigneeDrawer(false);
  };
  const [openChecklist, setOpenChecklist] = useState<boolean>(false);
  const [selectedDraftData, setSelectedDraftData] = useState<any>();

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumberChange(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const { data: contractData } = useQuery({
    queryKey: QueryKeyGenerator.getChoiceFetchingQuery(),
    queryFn: async () => await getContractTypes(),
  });

  const { data: draftAssignee } = useQuery({
    queryKey: ['assignee'],
    queryFn: async () => {
      const response = await fetchAssignee();
      return response.results;
    },
  });

  const handleVersionDrawerClose = () => {
    setOpenVersionDrawer(false);
    setDraftRowData({});
  };

  const handleVersionDrawerOpen = (data: any) => {
    setOpenVersionDrawer(true);
    setDraftRowData(data);
  };

  const { data: groupData } = useQuery({
    queryKey: ['groups'],
    queryFn: async () => {
      const response = await fetchGroups();
      return response;
    },
  });

  const user_data = React.useMemo(() => getSessionStorage('user_profile'), []);

  const isOwner = React.useCallback(
    (owners: any) => {
      const index = owners.findIndex(
        (owner: any) => owner?.id === user_data?.id
      );
      if (index > -1) {
        return true;
      }
      return false;
    },
    [user_data]
  );

  const canAccessDraft = React.useCallback(
    (data: any) => {
      const approvalData = data?.approvers?.filter(
        (item: any) =>
          item?.id === user_data?.id && item?.user_type !== 'external'
      );
      const collaboratorData = data?.collaborators?.filter(
        (item: any) =>
          item?.id === user_data?.id && item?.user_type !== 'external'
      );
      const signatoryData = data?.signatories?.filter(
        (item: any) =>
          item?.id === user_data?.id && item?.user_type !== 'external'
      );
      const preSignatoryData = data?.pre_signatories?.filter(
        (item: any) =>
          item?.id === user_data?.id && item?.user_type !== 'external'
      );
      const owner = isOwner(data?.owners);
      const creator = data?.creator?.id === user_data?.id;
      const draftViewer = data?.draft_viewers?.filter(
        (item: any) => item === user_data?.id
      );
      if (
        owner ||
        approvalData.length ||
        collaboratorData?.length ||
        signatoryData?.length ||
        preSignatoryData?.length ||
        creator ||
        draftViewer?.length
      ) {
        return true;
      }
      return false;
    },
    [user_data, isOwner]
  );

  const handleContractNameClick = React.useCallback(
    (canAccess: boolean, version: string, id: string) => {
      if (canAccess) {
        window.open(`/draft/${version}/${id}`);
      } else {
        enqueueSnackbar('You are not assigned any role for this draft!', {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    []
  );

  const handleContractTypeClick = React.useCallback(
    (contractType: any) => {
      setFilters((prev: IObject) => ({
        ...prev,
        contractType: [contractType?.name],
      }));
      if (contractType?.name) {
        setPageNumberChange(0);
      }
      setSearchContractType(true);
    },
    [handleFilterChange]
  );

  const handleGroupClick = React.useCallback(
    (groups: any) => {
      handleFilterChange('groups', groups);
      setSearchGroups(true);
    },
    [handleFilterChange]
  );

  const handleOpenChecklistDrawer = (data: any) => {
    setSelectedDraftData(data);
    setOpenChecklist(true);
  };

  const statusOptions = [
    { name: 'Committed for Signature' },
    { name: 'Contract Executed Successfully' },
    { name: 'Counter Party Draft Created' },
    { name: 'Draft Creation Pending' },
    { name: 'Internal Draft Created' },
    { name: 'Requisition Approval Pending' },
    { name: 'Signature Aborted' },
    { name: 'Signature Pending' },
  ];

  const columns = React.useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'contractName',
        headerName: 'Title',
        minWidth: 200,
        flex: 1,
        sortable: !searchDraft,
        renderHeader: () => {
          return searchDraft ? (
            <TableSearchInputComponent
              key="draft_name"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, name: null }));
                setSearchDraft(false);
              }}
              placeholder="Search Title"
              handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
                handleFilterChange('name', e.target.value)
              }
            />
          ) : (
            <TableHeaderWithSearch
              title="Draft Name"
              setIsSearch={setSearchDraft}
            />
          );
        },
        renderCell: (Params: any) => {
          const canAccess = canAccessDraft(Params.row);
          return (
            <GridCellExpand
              value={Params?.row?.contractName}
              width={Params.colDef.computedWidth}
              onClick={() =>
                handleContractNameClick(
                  canAccess,
                  Params.row.version,
                  Params.row?.id
                )
              }
              cellStyle={{
                cursor: 'pointer',
              }}
            />
          );
        },
      },
      {
        field: 'contractType',
        headerName: 'Contract Type',
        minWidth: 240,
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Chip
            sx={{
              background: '#C4DBFF',
              color: 'riSecondary.900',
            }}
            label={<OverflowTip value={params?.row?.contractType?.name} />}
            onClick={() => handleContractTypeClick(params?.row?.contractType)}
          />
        ),
        renderHeader: () => {
          return searchContract ? (
            <TableSearchInputComponent
              key="contractType"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, created_by: null }));
                setSearchContractType(false);
              }}
              placeholder="Search contract type"
              selectedValue={filters?.contractType ? filters.contractType : []}
              renderCustomInput={() => (
                <TableSearchSelectInput
                  key={
                    isArray(filters?.contractType)
                      ? (filters?.contractType?.[0] as string)
                      : 'contractType'
                  }
                  label="Select contract type"
                  options={contractData}
                  valueKey="name"
                  value={filters?.contractType ? filters.contractType : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('contractType', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Contract Type"
              setIsSearch={setSearchContractType}
            />
          );
        },
      },
      {
        field: 'version',
        headerName: 'Version',
        minWidth: 90,
        flex: 1,
        renderCell: (Params: any) => (
          <Button
            sx={tableButtonStyle}
            onClick={() => {
              handleVersionDrawerOpen(Params?.row);
            }}
          >
            {Params?.row?.version}
          </Button>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 240,
        flex: 1,
        sortable: !searchStatus,
        renderHeader: () => {
          return searchStatus ? (
            <TableSearchInputComponent
              key="status"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, status: null }));
                setSearchStatus(false);
              }}
              placeholder="Search Status"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key={
                    isArray(filters?.status)
                      ? (filters?.status?.[0] as string)
                      : 'status'
                  }
                  options={statusOptions}
                  label="Select Status"
                  valueKey="name"
                  value={filters?.status ? filters.status : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('status', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Status"
              setIsSearch={setSearchStatus}
            />
          );
        },
      },
      {
        field: 'checklist',
        headerName: 'Checklist',
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (Params: any) => (
          <Button
            sx={tableButtonStyle}
            onClick={() => handleOpenChecklistDrawer(Params?.row)}
          >
            {Params?.row?.checklist}
          </Button>
        ),
      },
      {
        field: 'groups',
        headerName: 'Groups',
        maxWidth: 300,
        width: 250,
        sortable: false,
        minWidth: 150,
        renderCell: (params) => (
          <TableChipList
            id={`groups-${params?.row?.id}`}
            labelKey=""
            valueKey=""
            chipColor="#DCBDE7"
            list={params?.row?.groups || []}
            onClick={handleGroupClick}
          />
        ),
        renderHeader: () => {
          return searchGroups ? (
            <TableSearchInputComponent
              key="groups"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, groups: null }));
                setSearchGroups(false);
              }}
              placeholder="Search Groups"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key={
                    isArray(filters?.groups)
                      ? (filters?.groups?.[0] as string)
                      : 'groups'
                  }
                  options={groupData}
                  label="Select Groups"
                  valueKey="name"
                  value={filters?.groups ? filters.groups : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('groups', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Groups"
              setIsSearch={setSearchGroups}
            />
          );
        },
      },
      {
        field: 'assignee',
        headerName: 'Assigned to',
        maxWidth: 300,
        width: 250,
        sortable: false,
        minWidth: 150,
        renderHeader: () => {
          return searchAssignee ? (
            <TableSearchInputComponent
              key="assignees"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, assignees: null }));
                setSearchAssignee(false);
              }}
              placeholder="Search assignees"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key={
                    isArray(filters?.assignees)
                      ? (filters?.assignees?.[0] as string)
                      : 'assignees'
                  }
                  options={draftAssignee}
                  label="Select assignees"
                  valueKey="name"
                  value={filters?.assignees ? filters.assignees : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('assignees', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Assigned to"
              setIsSearch={setSearchAssignee}
            />
          );
        },
        renderCell: (Params) => {
          const signatories = Params?.row?.signatories || [];
          const pre_signatories = Params?.row?.pre_signatories || [];
          const collaborators =
            Params?.row?.signatories?.length === 0
              ? Params?.row?.collaborators
              : [];
          const owners = Params?.row?.owners || [];
          const approvers_ = Params?.row?.approvers || [];

          let assigneeData: any = [];
          const approvers = approvers_.map((i: any) => {
            i['assigned_role'] = ['Approver'];
            return i;
          });
          const collaborator = collaborators.map((i: any) => {
            i['assigned_role'] = ['Collaborators'];
            return i;
          });
          const signatoriesData = signatories.map((i: any) => {
            i['assigned_role'] = ['Signatories'];
            return i;
          });
          const preSignatories = pre_signatories?.map((i: any) => {
            i['assigned_role'] = ['Pre Signatories'];
            return i;
          });
          const ownersData = owners?.map((i: any) => {
            i['assigned_role'] = ['Owners'];
            return i;
          });

          let creator: any = null;
          if (Params?.row?.creator?.roles?.includes('Creators')) {
            creator = Params?.row?.creator;
            creator['assigned_role'] = ['Creators'];
          }

          // For AvatarList data
          assigneeData = [
            ...collaborator,
            ...approvers,
            ...signatoriesData,
            ...preSignatories,
            ...ownersData,
            ...(creator ? [creator] : []),
          ];
          const ids = assigneeData.map(
            ({ id, email }: { id?: string; email?: string }) => id || email
          );
          assigneeData = assigneeData.filter(
            ({ id, email }: { id?: string; email?: string }, index: number) =>
              !ids.includes(id || email, index + 1)
          );

          const handleClick = (draftRowData: any) => {
            setOpenAssigneeDrawer(true);
            setDraftRowData(draftRowData);
          };
          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Box>
                {assigneeData?.length > 0 ? (
                  <AvatarList data={assigneeData} Params={Params} />
                ) : (
                  <Button onClick={() => handleClick(Params?.row)}>
                    Start Assignee
                  </Button>
                )}
              </Box>
              {assigneeData?.length > 0 && (
                <IconButton onClick={() => handleClick(Params?.row)}>
                  <CallMadeIcon style={{ color: '#6D264C' }} />
                </IconButton>
              )}
            </Stack>
          );
        },
      },
      {
        field: 'Pending Since',
        headerName: 'Pending Since',
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          const createdOn = params?.row?.created_on;
          const today = dayjs().startOf('day');
          const createdDate = dayjs(createdOn).startOf('day');
          const daysDifference = today.diff(createdDate, 'day');
          return (
            <Box color="#6D264C">
              {daysDifference > 1
                ? `${daysDifference} days`
                : `${daysDifference} day`}
            </Box>
          );
        },
      },
    ],
    [
      searchDraft,
      setFilters,
      handleFilterChange,
      canAccessDraft,
      handleContractNameClick,
      handleContractTypeClick,
      searchContract,
      filters,
      contractData,
      handleGroupClick,
      searchGroups,
      groupData,
      searchAssignee,
      draftAssignee,
      searchStatus,
    ]
  );

  const handleCLoseChecklist = () => {
    setOpenChecklist(false);
  };

  return (
    <>
      <DataGridTable
        rows={data?.results || []}
        columns={columns}
        rowCount={data?.count}
        isLoading={isLoading}
        setSelectedRow={setSelectedRow}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        setSorting={setSorting}
        checkboxSelection
      />

      <DrawerComponent
        open={openVersionDrawer}
        onClose={handleVersionDrawerClose}
        drawerHeader="Version History"
        sx={{
          '& .MuiDrawer-paper': {
            width: '550px',
          },
        }}
      >
        <VersionDrawer
          draftRowData={draftRowData}
          setDraftRowData={setDraftRowData}
          handleVersionDrawerClose={handleVersionDrawerClose}
        />
      </DrawerComponent>

      <DrawerComponent
        open={openChecklist}
        onClose={handleCLoseChecklist}
        sx={{
          '& .MuiDrawer-paper': {
            width: '550px',
          },
        }}
        drawerHeader={`Contract checklist: ${selectedDraftData?.contractName}`}
      >
        <CheckListComponent
          selectedDraftData={selectedDraftData}
          openChecklist={openChecklist}
        />
      </DrawerComponent>

      <DrawerComponent
        drawerHeader={`Assign Co-Owner : ${draftRowData.contractName}`}
        open={openAssigneeDrawer}
        onClose={handleCloseAssigneeDrawer}
      >
        <AssigneeDrawer row={draftRowData} />
      </DrawerComponent>
    </>
  );
};

export default DraftTable;
