import React, { lazy } from 'react';

import { createBrowserRouter } from 'react-router-dom';

import UserAuthChecker from './HOC/auth';
import VerifyExternalUser from '../../ExternalUserFlow/VerifyExternalUser';
import Root from '../../Root';
import Search from '../../Search/Components/search';

const Admin = lazy(() => import('../../Admin'));
const ApprovalsContainer = lazy(
  () => import('../../Approvals/Container/ApprovalsContainer')
);
const ClauseLibraryAdminComponent = lazy(
  () =>
    import('../../ClauseLibrary/Admin/Component/ClauseLibraryAdminComponent')
);
const DataDictionaryAdminContainer = lazy(
  () => import('../../DataDictionary/Admin')
);
const DataDictionaryCreateType = lazy(
  () => import('../../DataDictionary/Admin/Component/DataDictionaryCreateType')
);
const DocumentLibraryComponent = lazy(
  () => import('../../DocumentLibrary/Component/DocumentLibraryComponent')
);
const DocumentView = lazy(
  () => import('../../DocumentView/Container/documentCon')
);
const ViewDraft = lazy(() => import('../../Draft/Component/ViewDraft'));
const DraftContainer = lazy(
  () => import('../../Draft/Container/DraftContainer')
);
const FilterContainer = lazy(
  () => import('../../Filter/Container/FilterContainer')
);
const ReminderComponent = lazy(
  () => import('../../Reminder/Container/ReminderCon')
);
const DraftLayout = lazy(
  () => import('../../RiverusUI/Components/Layouts/DraftLayout')
);
const SearchComponent = lazy(() => import('../../Search/Container/SearchCon'));
const StampBank = lazy(() => import('../../StampBank'));
const AdminTemplate = lazy(() => import('../../Templates/Admin'));
const ViewTemplate = lazy(
  () => import('../../Templates/Admin/Component/ViewTemplate')
);

export const privateRouter = createBrowserRouter([
  {
    path: '*',
    Component: UserAuthChecker(Root),
    children: [
      {
        path: 'document/:page/:id/:requestid?',
        element: <DocumentView />,
      },
      {
        path: 'search/:query',
        element: <SearchComponent />,
      },
      {
        path: 'documentlibrary',
        element: <DocumentLibraryComponent />,
      },
      {
        path: 'reminders',
        element: <ReminderComponent />,
      },
      {
        path: 'templates',
        element: <AdminTemplate />,
      },
      {
        path: 'admin/templates',
        element: <AdminTemplate />,
      },
      {
        path: 'template/:id/:page',
        element: <ViewTemplate />,
      },
      {
        path: 'admin/stamp-bank',
        element: <StampBank />,
      },
      {
        path: 'draftingreview',
        element: <DraftContainer />,
      },
      {
        path: 'draftingreview/:id',
        element: <DraftContainer />,
      },
      {
        path: 'approvals',
        element: <ApprovalsContainer />,
      },
      {
        path: 'admin/data-dictionary/:id/',
        element: <DataDictionaryCreateType />,
      },
      {
        path: 'admin/data-dictionary',
        element: <DataDictionaryAdminContainer />,
      },
      {
        path: 'admin/clause-library-admin',
        element: <ClauseLibraryAdminComponent />,
      },
      {
        path: 'admin',
        element: <Admin />,
      },
      {
        path: 'filter',
        element: <Search />,
      },
      {
        path: 'draft',
        element: <DraftLayout />,
        children: [{ path: ':version/:id', element: <ViewDraft /> }],
      },
    ],
  },
]);

export const externalRouter = createBrowserRouter([
  {
    path: '*',
    children: [
      {
        path: 'draft/:version/:id',
        element: <VerifyExternalUser />,
      },
    ],
  },
]);
