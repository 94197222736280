import React, { Dispatch, SetStateAction } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Control, Controller } from 'react-hook-form';

interface Props {
  allowedFileTypes: string[];
  label: string;
  control?: Control;
  name: any;
  files: any[];
  setFiles: Dispatch<SetStateAction<any>>;
}

const MultiUploadNotes: React.FC<Props> = ({
  allowedFileTypes,
  label,
  control,
  name,
  files,
  setFiles,
}) => {
  const handleFileUploadChanges = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const uploadNewFile = event.target.files;
    if (uploadNewFile) {
      const isAlreadyUploaded = files?.some(
        (file: any) => file?.[0]?.name === uploadNewFile?.[0]?.name
      );
      if (isAlreadyUploaded) {
        enqueueSnackbar('This file is already uploaded!', {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        return;
      } else {
        setFiles([uploadNewFile, ...files]);
      }
    }
  };

  const handleDeleteFiles = (name: string) => {
    const filterFiles = files?.filter((file) => file?.[0]?.name !== name);
    setFiles(filterFiles);
  };

  return (
    <Stack spacing={2} marginBottom="15px" alignItems="start">
      <Controller
        name={name}
        control={control}
        render={() => (
          <TextField
            variant="outlined"
            label={label}
            fullWidth
            disabled
            helperText={`Supported file formats:${allowedFileTypes
              .join(', ')
              .replace(/,([^,]*)$/, ' and$1')}`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <label htmlFor={`upload-doc`}>
                    <input
                      type="file"
                      id={`upload-doc`}
                      style={{ display: 'none' }}
                      accept={allowedFileTypes.join(', ')}
                      onChange={(e) => handleFileUploadChanges(e)}
                    />
                    <IconButton component="span">
                      <FileUploadOutlinedIcon />
                    </IconButton>
                  </label>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      {files?.length > 0 &&
        files?.map((file: any, index: number) => (
          <Stack
            key={index}
            direction="row"
            justifyContent="space-between"
            width="100%"
          >
            <Typography>{file?.[0]?.name}</Typography>
            <IconButton onClick={() => handleDeleteFiles(file?.[0]?.name)}>
              <DeleteOutlinedIcon />
            </IconButton>
          </Stack>
        ))}
    </Stack>
  );
};

export default MultiUploadNotes;
