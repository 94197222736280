import React, { useCallback, useEffect, useMemo, useState } from 'react';

import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Docxtemplater from 'docxtemplater';
import { useSnackbar } from 'notistack';
import PizZip from 'pizzip';
import { FormProvider, useForm } from 'react-hook-form';

import DraftForm from './DraftForm';
import MultiUploadNotes from './MultiUploadNotes';
import { formTypeOptions } from './staticData';
import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { getSessionStorage } from '../../../Authentication/Actions/authentication';
import { Blank_Document_link } from '../../../Constants/const';
import { isRiverusAdmin } from '../../../DataDictionary/DDUtils';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import NotepadComponent from '../../../RiverusUI/Components/NotepadComponent';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  fetchContractType,
  fetchDraftGroups,
  fetchGroups,
  fetchRequestApprovals,
} from '../../../Services/Approval';
import {
  createDraft,
  fetchPolicyContractTypes,
  fetchTemplate,
  getS3PresignedUrl,
  getS3ReferencePresignedURL,
  referenceDocument,
  sendEmail,
  upload_file_in_s3_bucket,
} from '../../../Services/Draft';
import { draftStatus } from '../../State/DraftState';
import LinkSelectedTemplateField from '../TemplateDrawer/LinkSelectedTemplateField';

interface IProps {
  onClose: VoidFunction;
}

const SparkMD5 = require('spark-md5');

const CreateDraft: React.FC<IProps> = ({ onClose }) => {
  const [formTypes, setFormTypes] = useState(formTypeOptions);
  const [counterPartyDraft, setCounterPartyDraft] = useState<any>([]);
  const [selectedLinkedDraft, setSelectedLinkedDraft] = useState<any>(null);
  const [formData, setFormData] = useState<any>();
  const [uploadedFile, setUploadedFile] = useState<any[]>([]);
  const [draftDetails, setDraftDetails] = useState<any>({});
  const [allReferenceDocs, setAllReferenceDocs] = useState<any[]>([]);
  const [ownReferenceFileData, setOwnReferenceFileData] = useState<any>({});
  const [fixedGroups, setFixedGroups] = useState<any[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const methods = useForm();

  const { handleSubmit, control, watch, resetField, setValue } = methods;

  const form_type = watch('createFrom');
  const contract_type = watch('contractType');
  const template = watch('template');
  const draft_type = watch('draft_type');
  const can_upload_draft = watch('can_upload_draft');
  const can_link_draft = watch('can_link_draft');

  useEffect(() => {
    resetField('createFrom');
  }, [contract_type, resetField]);

  const user_data: any = useMemo(() => getSessionStorage('user_profile'), []);

  const userIsCreator = useMemo(
    () => user_data?.roles?.includes('Creators'),
    [user_data?.roles]
  );

  const { data: contractData, isLoading: contractLoading } = useQuery({
    queryKey: ['contract_data'],
    queryFn: fetchContractType,
    select: (response: any) => response.results,
    enabled: !userIsCreator,
  });

  const { data: creatorContractType, isLoading: contractTypeLoading } =
    useQuery({
      queryKey: ['creator_contract_type'],
      queryFn: fetchPolicyContractTypes,
      select: (response: any) => response.results,
      enabled: !!userIsCreator,
    });

  const { data: contractRequestData } = useQuery({
    queryKey: ['contract_type_has_policy', contract_type],
    queryFn: () => {
      const params = `?approval_type=contract_request&contract=${contract_type}`;
      return fetchRequestApprovals(params);
    },
    enabled: !!contract_type,
  });

  const { data: groupListData, isLoading: groupLoading } = useQuery({
    queryKey: ['GroupList'],
    queryFn: async () => await fetchGroups(),
  });

  const { data: draftGroups } = useQuery({
    queryKey: ['draft_groups', contract_type],
    queryFn: async () => await fetchDraftGroups(contract_type),
    select: (response: any) => response?.groups,
    enabled: !!contract_type,
  });

  useEffect(() => {
    const groups = [
      ...new Set([...(draftGroups || []), ...(user_data?.groups || [])]),
    ];
    setValue('groups', groups);
    setFixedGroups(groups);
  }, [draftGroups, setValue, user_data?.groups]);

  const { data: getTemplateList, isLoading: loadingTemplateList } = useQuery({
    queryKey: ['template_list'],
    queryFn: async () => {
      const response = await fetchTemplate();
      return response?.results;
    },
  });

  const templateList = useMemo(
    () =>
      getTemplateList?.filter((items: any) => {
        return items?.contract_type?.id === contract_type;
      }),
    [getTemplateList, contract_type]
  );

  const selectedContractData = useMemo(() => {
    if (userIsCreator) {
      const selectedContract = creatorContractType?.filter(
        (data: any) => data.id === contract_type
      );
      return selectedContract?.[0];
    } else {
      const selectedContract = contractData?.filter(
        (data: any) => data.id === contract_type
      );
      return selectedContract?.[0];
    }
  }, [contractData, contract_type, creatorContractType]);

  useEffect(() => {
    if (contract_type && templateList) {
      const updateDraftOptions = [...formTypeOptions];
      if (templateList?.length === 0) {
        const option = {
          value: 'template',
          title: '',
          description: `No templates found under contract type “${selectedContractData?.name}".`,
          disabled: true,
        };
        updateDraftOptions[0] = option;
      } else {
        updateDraftOptions[0].disabled = false;
      }
      setFormTypes(updateDraftOptions);
    }
  }, [selectedContractData, contract_type, templateList]);

  const selectedTemplate = React.useMemo(() => {
    const temp = templateList?.filter((data: any) => data.id === template);
    return temp?.[0];
  }, [template, templateList]);

  const handleUploadAllRefDoc = (refPayload: any[] = []) => {
    const reference_documents: any[] = [];
    uploadedFile?.map((file: any) => {
      const fileData = {
        file_name: file?.[0]?.name,
        file_type: 'note_for_approval',
      };
      reference_documents.push(fileData);
    });

    const payload = {
      reference_documents: [...refPayload, ...reference_documents],
      file_type: 'reference_document',
    };

    uploadReferenceDocument(payload);
  };

  const uploadBlankDoc = async (data: any) => {
    if (data) {
      const reference_documents: any[] = [];
      let noteFile: any[] = [];
      let policyFile: any[] = [];

      const processDocument = async (
        content: string,
        file_name_suffix: string,
        file_type: string
      ) => {
        const templateFile = await fetch(Blank_Document_link.NOTES_BLANK_DOC);
        const templateData = await templateFile.arrayBuffer();
        const zip = new PizZip(templateData);
        const doc = new Docxtemplater().loadZip(zip);
        const docContent = content.replace(/<\/?p>/g, '');
        doc.setData({ content: docContent });
        doc.render();

        const generatedDocx = doc.getZip().generate({
          type: 'blob',
          mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });

        const file = generatedDocx;
        file['name'] = `${data.contractName}_${file_name_suffix}.docx`;
        const reader = new FileReader();

        if (file) {
          reader.readAsDataURL(file);
          reader.onload = async () => {
            reference_documents.push({
              file_name: file?.name,
              file_type: file_type,
            });
            if (file_type === 'draft_notes') {
              noteFile = [file];
            } else if (file_type === 'policy_notes') {
              policyFile = [file];
            }
          };
        }

        return new Promise<void>((resolve) => {
          reader.onloadend = () => {
            resolve();
          };
        });
      };

      if (data?.notes) {
        await Promise.all([
          processDocument(data?.notes, 'notes', 'draft_notes'),
        ]);

        if (!data?.policy_note) {
          setAllReferenceDocs([noteFile, ...uploadedFile]);
          handleUploadAllRefDoc(reference_documents);
        }
      }

      if (data?.policy_note) {
        await Promise.all([
          processDocument(data?.policy_note, 'policy_notes', 'policy_notes'),
        ]);
        if (!data?.notes) {
          setAllReferenceDocs([policyFile, ...uploadedFile]);
        } else {
          setAllReferenceDocs([noteFile, policyFile, ...uploadedFile]);
        }
        handleUploadAllRefDoc(reference_documents);
      }
    }
  };

  const { mutate: createDraftMutation, isPending: createDraftLoading } =
    useMutation({
      mutationKey: ['create_draft'],
      mutationFn: createDraft,
      onSuccess: (response: any) => {
        enqueueSnackbar('Draft added successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        queryClient.invalidateQueries({
          queryKey: ['draft_statistics'],
        });
        const payload: any = {
          draft: response?.data?.draftID,
        };
        if (
          !response?.data?.policy_note &&
          !response?.data?.notes &&
          uploadedFile?.length === 0 &&
          !can_upload_draft
        ) {
          emailMutation(payload);
          queryClient.invalidateQueries({
            queryKey: ['drafts'],
          });

          onClose();
          if (response.data) {
            window.open(`/draft/${response.data.version}/${response.data.id}`);
          }
        } else {
          setDraftDetails(response?.data);
          if (response?.data?.notes) {
            uploadBlankDoc(response?.data);
          } else if (response?.data?.policy_note) {
            uploadBlankDoc(response?.data);
          } else if (uploadedFile?.length > 0) {
            handleUploadAllRefDoc();
            setAllReferenceDocs([...uploadedFile]);
          } else if (can_upload_draft) {
            const payload = [
              {
                ...ownReferenceFileData,
                draft: response?.data?.draftID,
              },
            ];
            uploadReferenceDocLinkinDraft(payload);
          }
        }
      },
      onError: (error: any) => {
        const responseData = error?.response?.data?.detail;
        enqueueSnackbar(`${responseData || 'Failed to create Draft!'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: emailMutation } = useMutation({
    mutationKey: ['send_email'],
    mutationFn: sendEmail,
    onSuccess: () => {
      enqueueSnackbar('Email sent successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Email is not sent successfully!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadFileInS3Bucket } = useMutation({
    mutationKey: ['upload_draft_to_s3'],
    mutationFn: upload_file_in_s3_bucket,
    onSuccess: (response: any) => {
      if (draft_type !== 'request_draft') {
        createDraftMutation({ ...formData, link: response.file_path });
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadReferenceFileInS3Bucket } = useMutation({
    mutationKey: ['upload_draft_to_s3'],
    mutationFn: upload_file_in_s3_bucket,
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadReferenceDocLinkinDraft } = useMutation({
    mutationKey: ['upload_reference_doc_link_in_draft'],
    mutationFn: referenceDocument,
    onSuccess: () => {
      const payload: any = {
        draft: draftDetails?.draftID,
        notes: true,
      };
      emailMutation(payload);
      onClose();
      window.open(`/draft/${draftDetails?.version}/${draftDetails?.id}`);
      queryClient.invalidateQueries({
        queryKey: ['drafts'],
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onUploadProgress = React.useCallback(
    (progressEvent: any) => {
      const reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      const file = counterPartyDraft[0];
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [counterPartyDraft]
  );

  const { mutate: uploadDocument } = useMutation({
    mutationKey: ['upload_draft_document', counterPartyDraft],
    mutationFn: getS3PresignedUrl,
    onSuccess: (response: any) => {
      if (response.data) {
        setFormData((prevState: any) => ({
          ...prevState,
          link: response?.data.presigned_url.file_path,
        }));
        if (can_upload_draft) {
          setOwnReferenceFileData((prevState: any) => ({
            ...prevState,
            link: response?.data.presigned_url.file_path,
          }));
        }
        const file = counterPartyDraft[0];
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };
          uploadFileInS3Bucket({
            presignedPostData: response?.data?.presigned_url,
            file: file,
            onHandleFileProgress: onHandleFileProgress,
          });
        }
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const getFileType = (url: string) => {
    if (url.includes('draft_notes')) {
      return 'draft_notes';
    } else if (url.includes('policy_notes')) {
      return 'policy_notes';
    } else if (url.includes('note_for_approval')) {
      return 'note_for_approval';
    }
  };

  const { mutate: uploadReferenceDocument } = useMutation({
    mutationKey: ['upload_reference_document'],
    mutationFn: getS3ReferencePresignedURL,
    onSuccess: (response: any) => {
      if (response.data) {
        const presigned_urls = response?.data?.presigned_url;
        const referencePresignedUrls: any = [];
        presigned_urls?.map((presigned_url: any, index: number) => {
          allReferenceDocs?.map((fileData: any, i: number) => {
            if (index === i) {
              const reader = new FileReader();
              const file = fileData[0];
              if (file) {
                reader.readAsDataURL(file);
                const onHandleFileProgress = {
                  onUploadProgress: (progressEvent: any) =>
                    onUploadProgress(progressEvent),
                };
                uploadReferenceFileInS3Bucket({
                  presignedPostData: presigned_url,
                  file: file,
                  onHandleFileProgress: onHandleFileProgress,
                });
                reader.onload = async () => {
                  const hexHash = SparkMD5.hash(reader.result);
                  const file_ = {
                    file_hash: hexHash,
                    file_name: file.name,
                    file_size: file.size,
                    file_type: getFileType(presigned_url?.file_path),
                    draft: draftDetails?.draftID,
                    link: presigned_url?.file_path,
                  };
                  referencePresignedUrls.push(file_);
                  if (presigned_urls?.length - 1 === index) {
                    if (can_upload_draft) {
                      const payload = {
                        ...ownReferenceFileData,
                        draft: draftDetails?.draftID,
                      };
                      referencePresignedUrls.push(payload);
                    }
                    uploadReferenceDocLinkinDraft(referencePresignedUrls);
                  }
                };
              }
            }
          });
        });
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const contractApproversId = useMemo(() => {
    return contractRequestData?.results?.[0]?.approvers?.map(
      (approver: any) => approver?.id
    );
  }, [contractRequestData]);

  const onSubmit = useCallback(
    (data: any) => {
      if (!data.groups) {
        data.groups = ['/Org'];
      } else {
        data.groups = data?.groups.filter((group: string) => group !== null);
      }
      delete data.counter_party_draft;
      const commonPayload = {
        status:
          form_type === 'template'
            ? draftStatus.DRAFT_CREATION_PENDING
            : contractRequestData?.results?.length > 0
              ? draftStatus.REQUISITION_APPROVAL_PENDING
              : draftStatus.DRAFT_CREATION_PENDING,
        owners:
          user_data?.roles?.includes('Creators') &&
          contractRequestData?.results?.[0]?.approvers?.length
            ? contractApproversId
            : draft_type === 'request_draft'
              ? [user_data?.id, ...(data?.approvalTypes || [])]
              : [user_data?.id],
        creator: user_data?.roles?.includes('Creators') ? user_data?.id : '',
        approvers: contractApproversId,
        source: '',
        version: 0,
      };
      let payload;
      if (form_type === 'template') {
        payload = {
          ...data,
          ...commonPayload,
          template_link: selectedTemplate?.file_path,
        };
        createDraftMutation(payload);
      } else {
        payload = {
          ...data,
          ...commonPayload,
        };
        if (draft_type === 'counter_party') {
          if (!counterPartyDraft[0]) {
            enqueueSnackbar(
              'Please select the counter party draft to continue!',
              {
                variant: 'info',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              }
            );
          } else {
            const reader = new FileReader();
            const file = counterPartyDraft[0];
            if (file) {
              reader.readAsDataURL(file);
              reader.onload = async () => {
                const hexHash = SparkMD5.hash(reader.result);
                const file_ = {
                  file_hash: hexHash,
                  file_name: file.name,
                  file_size: file.size,
                  template_type: file['template_type'],
                  file_type: 'counter_party_drafts',
                  creation_type: isRiverusAdmin() ? 'system' : 'custom',
                };
                uploadDocument(file_);
              };
              payload.createFrom = 'counter_party';
              setFormData(payload);
            }
          }
        } else if (draft_type === 'request_draft') {
          if (!counterPartyDraft[0] && !selectedLinkedDraft) {
            enqueueSnackbar('Please select at least one reference document!', {
              variant: 'info',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          } else {
            let linkParam = null;
            if (can_link_draft) {
              if (selectedLinkedDraft?.type === 'executed') {
                const splitted: string[] =
                  selectedLinkedDraft?.file_name.split('.');
                const fileExtension = splitted[splitted.length - 1];
                linkParam = {
                  executed_contract_link: `${data.linked_draft}.${fileExtension}`,
                };
                data.createFrom = 'executed_contract';
              } else {
                linkParam = {
                  earlier_draft_link: selectedLinkedDraft?.link,
                };
                data.createFrom = 'earlier_draft';
              }
            }
            payload = {
              ...data,
              ...commonPayload,
              ...linkParam,
            };
            if (!can_upload_draft) createDraftMutation(payload);
            if (can_upload_draft) {
              const reader = new FileReader();
              const file = counterPartyDraft[0];
              if (file) {
                reader.readAsDataURL(file);
                reader.onload = async () => {
                  const hexHash = SparkMD5.hash(reader.result);
                  const file_ = {
                    file_hash: hexHash,
                    file_name: file.name,
                    file_size: file.size,
                    template_type: file['template_type'],
                    file_type: 'own_reference_document',
                    creation_type: isRiverusAdmin() ? 'system' : 'custom',
                  };
                  setOwnReferenceFileData({
                    file_hash: hexHash,
                    file_name: file.name,
                    file_size: file.size,
                    file_type: 'own_reference_document',
                  });
                  uploadDocument(file_);
                };
                payload.createFrom = can_link_draft
                  ? payload.createFrom
                  : 'own_reference_document';
                setFormData(payload);
              }
            }
          }
        }
      }
    },
    [
      can_link_draft,
      can_upload_draft,
      contractRequestData,
      counterPartyDraft,
      createDraftMutation,
      draft_type,
      enqueueSnackbar,
      form_type,
      selectedLinkedDraft,
      selectedTemplate,
      uploadDocument,
      user_data,
    ]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ padding: '30px 15px' }}>
          <Grid container gap={2}>
            <Grid item sm={9}>
              <Stack spacing={2}>
                <Typography marginBottom={2} fontWeight={'700'}>
                  Choose a starting point
                </Typography>
                <RISelectComponent
                  required
                  name="contractType"
                  control={control}
                  label="Select a contract type"
                  options={userIsCreator ? creatorContractType : contractData}
                  loading={
                    userIsCreator ? contractTypeLoading : contractLoading
                  }
                />
                <RadioButtonGroup
                  row
                  required
                  name="createFrom"
                  options={formTypes}
                  valueKey="value"
                  isDescription
                  control={control}
                />
                {form_type === 'draft' && (
                  <ControlledTextField
                    name="counter_party_name"
                    control={control}
                    label="Counterparty Name"
                    fullWidth
                  />
                )}
              </Stack>
            </Grid>

            {form_type === 'template' && (
              <React.Fragment>
                <Grid item sm={12}>
                  <Stack direction="row" spacing={2}>
                    <KeyboardReturnIcon
                      sx={{
                        transform: 'scaleX(-1)',
                        mt: '15px !important',
                      }}
                    />
                    <LinkSelectedTemplateField
                      control={control}
                      selectedContractId={contract_type}
                      name="template"
                      selectedContractName={selectedContractData?.name}
                      options={templateList}
                      loading={loadingTemplateList}
                      type={form_type}
                      selectedDisplayName={selectedContractData?.displayName}
                    />
                  </Stack>
                </Grid>
              </React.Fragment>
            )}
            {contractRequestData?.results?.length > 0 && (
              <Grid item sm={9}>
                <Stack direction="row" spacing={2}>
                  <KeyboardReturnIcon
                    sx={{
                      transform: 'scaleX(-1)',
                      mt: '15px !important',
                    }}
                  />
                  <Box width="100%">
                    <MultiUploadNotes
                      allowedFileTypes={['.pdf', '.docx']}
                      label="Upload note for approval"
                      name="note_for_approval"
                      files={uploadedFile}
                      setFiles={setUploadedFile}
                    />
                  </Box>
                </Stack>
              </Grid>
            )}
            {form_type === 'template' && (
              <Grid item sm={9}>
                <NotepadComponent name="notes" control={control} />
              </Grid>
            )}
            {form_type === 'draft' && (
              <DraftForm
                contract_name={selectedContractData?.name}
                contract_type={contract_type}
                display_name={selectedContractData?.displayName}
                counterPartyDraft={counterPartyDraft}
                setCounterPartyDraft={setCounterPartyDraft}
                setSelectedLinkedDraft={setSelectedLinkedDraft}
                contractRequestData={contractRequestData}
                user_data={user_data}
              />
            )}
            <Grid item sm={9}>
              <Stack spacing={2}>
                <Typography fontWeight={'700'}>Manage Access</Typography>
                <RISelectComponent
                  name="groups"
                  control={control}
                  label="Prescribing Department"
                  valueKey="name"
                  options={groupListData}
                  loading={groupLoading}
                  isMultiselect={true}
                  fixedValues={fixedGroups}
                  renderCustomComponent={(value: any) => (
                    <CustomChip key={value?.id} label={value?.name} />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item sm={9}>
              <Stack spacing={2}>
                <Typography fontWeight={'700'}>Name your Draft</Typography>
                <ControlledTextField
                  name="contractName"
                  control={control}
                  required
                  label="Draft Name"
                  fullWidth
                />
              </Stack>
              <Stack direction="row" marginTop={3}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={createDraftLoading}
                >
                  Continue
                </LoadingButton>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default CreateDraft;
