import React, { useEffect, useMemo } from 'react';

import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';

import ViewDraft from './ViewExternalDraft';
import {
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from '../Authentication/Actions/authentication';

const VerifyExternalUser = () => {
  const search = useLocation().search;
  const auth_token = new URLSearchParams(search).get('auth_token');
  const external_token = new URLSearchParams(search).get('external_token');
  const tab = new URLSearchParams(search).get('tab');
  const email = new URLSearchParams(search).get('email');
  const external_id = new URLSearchParams(search).get('external_id');

  const localExternalAuthToken = useMemo(
    () => getSessionStorage('external_auth_token'),
    []
  );

  useEffect(() => {
    if (auth_token && external_token) {
      setLocalStorage('external_auth_token', auth_token);
      setSessionStorage('external_auth_token', auth_token);
      setLocalStorage('external_token', external_token);
      setLocalStorage('external_email', email);
      setLocalStorage('external_user_id', external_id);
      if (!localExternalAuthToken || localExternalAuthToken !== auth_token) {
        window.location.reload();
      }
    }
  }, [auth_token, external_token, email, localExternalAuthToken]);

  return <Box>{tab && <ViewDraft activeTab={tab} />}</Box>;
};

export default VerifyExternalUser;
