import React, { useState } from 'react';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { IconButton, Stack, Typography } from '@mui/material';

import TemplateViewDrawer from './TemplateViewDrawer';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import ReusablePagination from '../../../RiverusUI/Components/ReusablePagination';

interface Props {
  templateList: any;
  control: any;
  name: string;
  labelKey: string;
  type: string;
}

const TemplateList: React.FC<Props> = ({
  templateList,
  control,
  name,
  labelKey,
  type,
}) => {
  const [openViewDrawer, setOpenViewDrawer] = useState<boolean>(false);
  const [templateId, setTemplateID] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const itemsPerPage = 10;

  const handleCloseViewDrawer = () => {
    setOpenViewDrawer(false);
  };

  const handleChangePage = (event: any, value: number) => {
    setPage(value);
  };

  const paginatedTemplates = templateList.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <>
      <Stack spacing={2} height="100%" justifyContent="space-between">
        <RadioButtonGroup
          name={name}
          control={control}
          options={paginatedTemplates}
          labelKey={labelKey}
          renderCustomLabel={(option: any) => (
            <Stack
              justifyContent="space-between"
              direction="row"
              width="350px"
              alignItems="center"
            >
              <Stack direction="column">
                <Typography className="title">{option?.[labelKey]}</Typography>
                <Typography className="description" variant="caption">
                  {option?.template_type}
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setOpenViewDrawer(true);
                  setTemplateID(option?.id);
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Stack>
          )}
        />
        <ReusablePagination
          count={Math.ceil(templateList?.length / itemsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
          sx={{ display: 'flex', justifyContent: 'end' }}
        />
      </Stack>
      <TemplateViewDrawer
        open={openViewDrawer}
        onClose={handleCloseViewDrawer}
        templateId={templateId}
        type={type}
      />
    </>
  );
};

export default TemplateList;
