import React, { useEffect, useState } from 'react';

import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import { Box, Stack, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  platform: string;
}

interface IconState {
  icon: any;
  name: string;
  text: string;
  displayName: string;
  path: string;
}

const umbrellaNav: IconState[] = [
  {
    icon: DriveFileRenameOutlineOutlinedIcon,
    name: 'documentlibrary',
    text: 'Access Document Library here',
    displayName: 'Contracts Library',
    path: '/documentlibrary',
  },
  // {
  //   icon: FilterAltOutlinedIcon,
  //   name: 'filter',
  //   text: 'Filter',
  //   displayName: 'Filters',
  //   path: '/filter',
  // },
];

const stylusNav = [
  {
    icon: DriveFileRenameOutlineOutlinedIcon,
    name: 'draftingreview',
    text: 'Manage your drafts here',
    displayName: 'Drafts',
    path: '/draftingreview',
  },
  {
    icon: NewspaperOutlinedIcon,
    name: 'templates',
    text: 'Manage your template here',
    displayName: 'Templates',
    path: '/templates',
  },
  {
    icon: FactCheckOutlinedIcon,
    name: 'approvals',
    text: 'Manage your approvals here',
    displayName: 'Approvals',
    path: '/approvals',
  },
];

const adminNav = [
  {
    icon: DatasetOutlinedIcon,
    name: 'data-dictionary-admin',
    text: 'Manage your data dictionary here',
    displayName: 'Data Dictionary',
    path: '/admin/data-dictionary',
  },
  {
    icon: SourceOutlinedIcon,
    name: 'clause-library-admin',
    text: 'Manage your clause library here',
    displayName: 'Clause Library',
    path: '/admin/clause-library-admin',
  },
  {
    icon: NewspaperOutlinedIcon,
    name: 'templates-admin',
    text: 'Manage your template here',
    displayName: 'Templates',
    path: '/admin/templates',
  },
  {
    icon: RequestPageOutlinedIcon,
    name: 'stamp-bank',
    text: 'Manage your Stamp here',
    displayName: 'Stamp Bank',
    path: '/admin/stamp-bank',
  },
];

export const SideNavbar: React.FC<Props> = (props) => {
  const { platform } = props;
  const location = useLocation();
  const { pathname } = location;
  const [navbarList, setNavbarList] = useState<any>([]);

  useEffect(() => {
    switch (platform) {
      case 'STYLLUS':
        setNavbarList(stylusNav);
        break;
      case 'UMBRELLA':
        setNavbarList(umbrellaNav);
        break;
      case 'ADMIN':
        setNavbarList(adminNav);
        break;
      default:
        break;
    }
  }, [platform]);

  return (
    <Box className="sidenav-layout">
      <Stack alignItems="center" spacing={4}>
        {navbarList.map((navItem: any, index: number) => {
          const Icon = navItem.icon;
          const isActive = pathname.includes(navItem.name);
          const color = isActive ? '#88305F' : '#1D1B20';
          return (
            <Stack
              alignItems="center"
              textAlign="center"
              key={index}
              spacing="5px"
              component={Link}
              to={navItem.path}
              sx={{
                textDecoration: 'none',
              }}
            >
              <Icon
                sx={{
                  color: color,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: color,
                }}
              >
                {navItem.displayName}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};

export default SideNavbar;
