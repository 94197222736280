import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { AddCircleOutline } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PercentIcon from '@mui/icons-material/Percent';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';

import AssigneeModal from './AssigneeModal';
import { currency_icon } from './static';
import {
  getLocalStorage,
  getSessionStorage,
} from '../../../Authentication/Actions/authentication';
import { Field_INFO } from '../../../Constants/DataDictionary';
import {
  editExternalBulkReminder,
  fetchExternalChecklistData,
  fetchExternalCollaborators,
  fetchOptionalFieldsExternal,
  saveExternalDraft,
} from '../../../ExternalUserFlow/Services/Draft';
import ControlledCheckbox from '../../../RiverusUI/Components/ControlledCheckbox';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import DeleteIcon from '../../../RiverusUI/Components/Icons/DeleteIcon';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import { fetchCollaborators } from '../../../Services/Approval';
import { editReminder } from '../../../Services/DocumentLibrary';
import {
  fetchChecklistData,
  fetchGetFields,
  fetchOptionalFields,
  saveDraft,
} from '../../../Services/Draft';
import {
  createBulkReminder,
  editBulkReminder,
  fetchReminderById,
  fetchReminderDraftItem,
} from '../../../Services/Reminder';
import { generateTags } from '../../../Templates/Component/DocGenerate';

interface Props {
  draftData: any;
  instance: any;
  setCompleteDraftFormData?: Dispatch<SetStateAction<any>>;
  setFieldUpdated?: Dispatch<SetStateAction<boolean>>;
  updateDraftData?: any;
  isExternal?: boolean;
  setSaveDraftLoading?: Dispatch<SetStateAction<boolean>>;
  isTemplateView?: boolean;
}

const CompleteDraft: React.FC<Props> = ({
  draftData,
  setCompleteDraftFormData,
  instance,
  setFieldUpdated,
  updateDraftData,
  isExternal,
  setSaveDraftLoading,
  isTemplateView = false,
}) => {
  const [openAssigneeDialog, setOpenAssigneeDialog] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<string[]>([]);
  const [assigneeDialogPropData, setAssigneeDialogPropData] = useState<any>({});
  const [tags, setTags] = useState({});
  const [expanded, setExpanded] = useState<string | false>(false);
  const [dynamicFields, setDynamicFields] = useState<any>([]);
  const [collaborators, setCollaborators] = useState<any>([]);
  const [tempReminder, setTempReminder] = useState<any>([]);
  const [fetchingOptionalField, setFetchingOptionalField] =
    useState<boolean>(false);
  const [shouldUpdateDynamicFields, setShouldUpdateDynamicFields] =
    useState(true);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParamsTypeValue = queryParams.get('reminder_type');
  const reminderId = queryParams.get('reminderId') || '';
  const external_token = queryParams.get('external_token') || '';

  const user_data = useMemo(() => getSessionStorage('user_profile'), []);
  const external_email = useMemo(() => getLocalStorage('external_email'), []);

  const userIsOwner = useMemo(
    () => draftData?.owners?.find((owner: any) => owner?.id === user_data?.id),
    [draftData, user_data]
  );

  const userIsCreator = useMemo(
    () => user_data?.roles?.includes('Creators'),
    [user_data?.roles]
  );

  const { data: complete_reminder_data } = useQuery({
    queryKey: ['get_complete_reminder_data', assigneeDialogPropData?.id],
    queryFn: async () =>
      await fetchReminderDraftItem(assigneeDialogPropData?.id),
    enabled: !!(
      assigneeDialogPropData?.id && assigneeDialogPropData?.reminder?.id
    ),
  });

  const { data: reminderData } = useQuery({
    queryKey: ['get_reminder_by_id'],
    queryFn: async () => await fetchReminderById(reminderId),
    enabled: !!reminderId,
  });

  const handleOpenAssigneeDialog = useCallback(
    (data: any) => {
      if (isExternal || isTemplateView) return;
      setOpenAssigneeDialog(true);
      setAssigneeDialogPropData(data);
    },
    [isExternal, isTemplateView]
  );

  const draft_id = useMemo(() => draftData?.id, [draftData?.id]);
  const contractTypeId = useMemo(
    () => draftData?.contractType?.id || draftData?.contract_type,
    [draftData]
  );
  const access_url = useMemo(() => draftData?.access_url, [draftData]);

  const methods = useForm();

  const { control, reset, handleSubmit } = methods;

  useEffect(() => {
    const collaborator = draftData?.collaborators?.map((data: any) => data.id);
    setCollaborators(collaborator);
  }, [draftData?.collaborators]);

  const handleCloseAssigneeDialog = useCallback(() => {
    if (queryParamsTypeValue === 'items') {
      navigate(pathname);
    }
    setOpenAssigneeDialog(false);
    setAssigneeDialogPropData({});
  }, [navigate, pathname, queryParamsTypeValue]);

  const handleAssignee = useCallback(
    (payload: any, selectUser: any, selectExternalUser: any) => {
      setShouldUpdateDynamicFields(false);

      const updatedFields = dynamicFields.map((fieldInfo: any) => {
        return {
          ...fieldInfo,
          field_data: fieldInfo?.field_data?.map((element: any) => {
            if (element?.field_name === assigneeDialogPropData?.field_name) {
              const shouldAssignDate =
                selectUser !== payload?.user ||
                selectExternalUser !== payload?.user;
              return {
                ...element,
                user_name: payload?.user_name,
                user_id: payload?.id,
                user: payload?.user,
                email: payload?.email,
                user_type: payload?.assignee_type,
                ...(shouldAssignDate
                  ? { assigned_on: payload?.assigned_on }
                  : {}),
              };
            }
            return element;
          }),
        };
      });
      setDynamicFields(updatedFields);
      if (payload?.assignee_type === 'internal') {
        const index = collaborators.findIndex(
          (collaborator: any) => collaborator === payload.id
        );
        if (index === -1) {
          setCollaborators((prev: any) => [...prev, payload.id]);
        }
      }
      handleCloseAssigneeDialog();
    },
    [
      dynamicFields,
      handleCloseAssigneeDialog,
      assigneeDialogPropData?.field_id,
      collaborators,
    ]
  );

  const handleReminder = useCallback(
    (payload: any) => {
      const filterTempReminder = tempReminder?.filter(
        (item: any) =>
          item?.field_name !== payload?.field_name ||
          item?.draft_item !== payload?.draft_item
      );
      setTempReminder([payload, ...filterTempReminder]);
    },
    [tempReminder, setTempReminder]
  );

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const { data: getFields } = useQuery({
    queryKey: ['get_fields', contractTypeId],
    queryFn: async () => {
      const response = await fetchGetFields(contractTypeId);
      return response?.data;
    },
    enabled: !!contractTypeId && !isExternal,
  });

  const { data: approversData } = useQuery({
    queryKey: ['Approvers_list', isExternal],
    queryFn: async () => {
      const response = isExternal
        ? await fetchExternalCollaborators()
        : await fetchCollaborators();
      const groups = response.results.map((data: any) => ({
        ...data,
        name: data?.first_name + ' ' + data?.last_name,
      }));
      return groups;
    },
    enabled: !!draftData?.groups,
  });

  const { mutate: update_bulk_reminders } = useMutation({
    mutationKey: ['update_bulk_reminders', isExternal],
    mutationFn: isExternal ? editExternalBulkReminder : editBulkReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to update Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { data: optionalFields, isFetching } = useQuery({
    queryKey: ['optional_fields', draft_id, isExternal],
    queryFn: async () => {
      setFetchingOptionalField(true);
      return isExternal
        ? await fetchOptionalFieldsExternal(draft_id)
        : await fetchOptionalFields(draft_id);
    },
    select: (response) => {
      return response?.results;
    },
    enabled: !!draft_id,
  });

  const { mutate: saveDraftMutation } = useMutation({
    mutationFn: isExternal ? saveExternalDraft : saveDraft,
    onMutate: () => {
      setSaveDraftLoading?.(true);
    },
    onSuccess: async () => {
      const latestOptionalFields = await queryClient.fetchQuery({
        queryKey: ['optional_fields', draft_id, isExternal],
        queryFn: async () => {
          return isExternal
            ? await fetchOptionalFieldsExternal(draft_id)
            : await fetchOptionalFields(draft_id);
        },
      });
      const latestCheckList = await queryClient.fetchQuery({
        queryKey: ['draft_checkList', draft_id],
        queryFn: async () => {
          return isExternal
            ? await fetchExternalChecklistData(draft_id)
            : await fetchChecklistData(draft_id);
        },
      });

      const checklistArray = Array.isArray(latestCheckList?.results)
        ? latestCheckList?.results
        : [];
      const optionalFieldsArray = Array.isArray(latestOptionalFields?.results)
        ? latestOptionalFields?.results
        : [];

      const checklistCollaborators = checklistArray
        .filter((item: any) => item?.user !== null && item?.user !== undefined)
        .map((item: any) => item?.user);

      const templateCollaborators = optionalFieldsArray
        ?.flatMap((field: any) =>
          Array.isArray(field?.field_data) ? field?.field_data : []
        )
        .filter((item: any) => item?.user !== null && item?.user !== undefined)
        .map((item: any) => item?.user);

      const uniqueCollaborators = Array.from(
        new Set([...templateCollaborators, ...checklistCollaborators])
      );

      setFieldUpdated?.(false);
      enqueueSnackbar('Save successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });

      const payload = {
        id: draftData?.id,
        body: {
          collaborators: uniqueCollaborators,
          ...(isExternal && {
            external_token: external_token,
          }),
        },
      };

      if (uniqueCollaborators?.length || isExternal) {
        await updateDraftData?.(payload);
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to save!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onSettled: () => {
      setSaveDraftLoading?.(false);
    },
  });

  const { mutate: bulkReminderMutation } = useMutation({
    mutationKey: ['create_bulk_reminder'],
    mutationFn: createBulkReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder created successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setTempReminder([]);
    },
    onError: () => {
      enqueueSnackbar('Failed to create reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: update_reminder } = useMutation({
    mutationKey: ['update_reminders'],
    mutationFn: editReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to update Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleUpdateReminder = useCallback((payload: any) => {
    const { id, ...remainingData } = payload;
    const updatedReminderPayload = {
      id: id,
      body: { ...remainingData },
    };
    update_reminder(updatedReminderPayload);
  }, []);

  const handleSaveDraft = React.useCallback(() => {
    if (dynamicFields?.length) {
      if (tempReminder?.length > 0) {
        bulkReminderMutation(tempReminder);
      }
      const dynamicFields_ = JSON.parse(JSON.stringify(dynamicFields));
      dynamicFields_?.forEach((fieldInfo: any) => {
        fieldInfo?.field_data?.forEach((fields: any) => {
          if (
            fields.data_type.toLowerCase() === 'boolean' ||
            fields.data_type.toLowerCase() === 'bulleted list' ||
            fields.data_type.toLowerCase() === 'numbered list'
          ) {
            fields.field_value = JSON.stringify(fields.field_value);
          }
        });
      });
      const payload = {
        draft: draftData?.id,
        optional_data: dynamicFields_,
      };
      saveDraftMutation(payload);
    }
  }, [
    bulkReminderMutation,
    draftData,
    dynamicFields,
    saveDraftMutation,
    tempReminder,
  ]);

  useEffect(() => {
    if (queryParamsTypeValue === 'items' && reminderId) {
      dynamicFields?.find((value: any) => {
        const fieldData = value?.field_data?.find(
          (item: any) => item?.id === reminderData?.draft_item
        );
        handleOpenAssigneeDialog(fieldData);
        return fieldData;
      });
    }
  }, [
    queryParamsTypeValue,
    reminderId,
    handleOpenAssigneeDialog,
    dynamicFields,
    reminderData,
  ]);

  useEffect(() => {
    if (
      !isFetching &&
      optionalFields?.length === 0 &&
      dynamicFields?.length > 0 &&
      !isTemplateView
    ) {
      handleSaveDraft();
    }
  }, [
    dynamicFields,
    handleSaveDraft,
    isFetching,
    isTemplateView,
    optionalFields,
  ]);

  useEffect(() => {
    if (optionalFields?.length && !isFetching && fetchingOptionalField) {
      setFormFields(optionalFields);
      if (shouldUpdateDynamicFields) {
        const optionalData = optionalFields.map((optionalField: any) => {
          optionalField.field_data.forEach((data: any) => {
            data.field_value = getFieldValue(
              data.data_type.toLowerCase(),
              data.field_value
            );
          });
          return optionalField;
        });
        setDynamicFields(optionalData);
      }
      setFetchingOptionalField(false);
    } else if (getFields?.length) {
      setFormFields(getFields);
    }
  }, [
    getFields,
    optionalFields,
    isFetching,
    fetchingOptionalField,
    shouldUpdateDynamicFields,
  ]);

  const updateDynamicFieldValue = (
    event: any,
    sectionIndex: number,
    fieldIndex: number,
    type: string
  ) => {
    const fields = [...dynamicFields];
    setFieldUpdated?.(true);
    if (
      type === 'text' ||
      type === 'select' ||
      type.toLowerCase() === 'currency'
    ) {
      fields[sectionIndex].field_data[fieldIndex].field_value =
        event.target.value;
      const { required, field_value, field_validation, validation } =
        fields[sectionIndex].field_data[fieldIndex];
      if (validation) {
        if (required && field_value.length > 0) {
          validation['errorMsg'] = '';
        }
        if (field_validation && event.target.value.length > field_validation) {
          validation['errorMsg'] =
            `Max character limit allowed is ${validation.maxCharLength} chars`;
        } else validation['errorMsg'] = '';
      }
    }
    if (type === 'duration') {
      fields[sectionIndex].field_data[fieldIndex].unit =
        event.target.value || 'Days';
    }
    if (type === 'percentage') {
      fields[sectionIndex].field_data[fieldIndex].field_value =
        event.target.value;
    }
    if (type.toLowerCase() === 'date') {
      fields[sectionIndex].field_data[fieldIndex].field_value = event.$d;
    }
    if (type === 'boolean') {
      fields[sectionIndex].field_data[fieldIndex].field_value =
        event.target.checked;
    }
    setDynamicFields(fields);
  };

  const parseTagList = (tags: any) => {
    const parsedTags = Object.keys(tags).reduce((acc, curr) => {
      let innerObject = {};
      if (Object.keys(tags[curr]).length > 0) {
        innerObject = { ...innerObject, ...tags[curr] };
      }
      return { ...acc, [curr.split(' ')[0]]: {}, ...innerObject };
    }, {});
    return parsedTags;
  };

  useEffect(() => {
    if (draft_id && optionalFields?.length === 0) {
      generateTags(access_url, (tags: any) => {
        const parseTags: any = parseTagList(tags);
        setTags(parseTags);
      });
    }
  }, [access_url, getFields, draft_id, optionalFields]);

  const getFieldName = (mergeTag: string, fieldName: string) => {
    let name = fieldName;
    if (optionalFields?.length) {
      return name;
    } else {
      try {
        if (
          mergeTag.charCodeAt(mergeTag.length - 1) >= 49 &&
          mergeTag.charCodeAt(mergeTag.length - 1) <= 57
        ) {
          name = name + ' ' + mergeTag[mergeTag.length - 1];
        }
        return name;
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getFieldValue = (type: string, value: any) => {
    if (type === 'boolean') {
      if (value === 'true') {
        return true;
      }
      return false;
    } else if (type === 'bulleted list' || type === 'numbered list') {
      if (value && value !== '') {
        return JSON.parse(value);
      }
      return [{ list: '' }];
    } else return value || '';
  };

  const constructDocFields = useCallback(() => {
    if (Object.keys(tags).length > 0) {
      const tagList = Object.keys(tags);
      const fields: any[] = [];
      tagList.forEach((tag) => {
        let sectionObj: any = {};
        for (let index = 0; index < formFields?.length; index++) {
          const section = JSON.parse(JSON.stringify(formFields[index]));
          let filteredTags: Field_INFO[] = [];
          filteredTags = section.field_data.filter((field: any) => {
            const tagToCheck = tag.replace(/\d+/g, '');
            const fieldName = field.field_name
              .replaceAll(/{|}/g, '')
              .replace(/\d+/g, '');
            return tagToCheck === fieldName;
          });

          if (filteredTags.length > 1) {
            filteredTags = filteredTags.filter((field: any) =>
              field.field_name.replaceAll(/{|}/g, '').includes(tag)
            );
          }
          if (filteredTags.length > 0) {
            sectionObj = { ...section };
            if (fields.length > 0) {
              const fieldIndex = fields.findIndex(
                (value) => value.section_name === section.section_name
              );
              if (fieldIndex > -1) {
                const field = JSON.parse(JSON.stringify(filteredTags[0]));
                field.display_name = getFieldName(tag, field.display_name);
                field.field_value = getFieldValue(
                  field.data_type.toLowerCase(),
                  field.field_value
                );
                field.unit = '';
                field.field_name = tag;
                field.field = tag.replace(/[0-9]/g, '');
                field.validation = {};
                fields[fieldIndex].field_data = [
                  ...fields[fieldIndex].field_data,
                  field,
                ];
                return;
              } else {
                sectionObj.field_data = [];
                const field = JSON.parse(JSON.stringify(filteredTags[0]));
                field.display_name = getFieldName(tag, field.display_name);
                field.field_value = getFieldValue(
                  field.data_type.toLowerCase(),
                  field.field_value
                );
                field.unit = '';
                field.validation = {};
                field.field = tag.replace(/[0-9]/g, '');
                field.field_name = tag;
                sectionObj.field_data = [field];
                fields.push(sectionObj);
                return;
              }
            } else {
              sectionObj.field_data = [];
              const field = JSON.parse(JSON.stringify(filteredTags[0]));
              field.display_name = getFieldName(tag, field.display_name);
              field.field_value = getFieldValue(
                field.data_type.toLowerCase(),
                field.field_value
              );
              field.unit = '';
              field.validation = {};
              field.field = tag.replace(/[0-9]/g, '');
              field.field_name = tag;
              sectionObj.field_data = [field];
              fields.push(sectionObj);
              return;
            }
          }
        }
      });
      setDynamicFields(fields);
    }
  }, [tags, formFields]);

  useEffect(() => {
    if (tags && optionalFields?.length === 0 && !isFetching) {
      constructDocFields();
    }
  }, [tags, optionalFields, isFetching]);

  useEffect(() => {
    if (dynamicFields?.length) {
      let defaultFields = {};
      dynamicFields.map((fields: any) => {
        fields.field_data?.map((data: any) => {
          const form_fields = {
            [data.field_name]: data.field_value,
          };
          defaultFields = {
            ...defaultFields,
            ...form_fields,
          };
        });
      });
      reset(defaultFields);
    }
  }, [dynamicFields, reset]);

  useEffect(() => {
    if (dynamicFields?.length) {
      const dynamicFields_ = JSON.parse(JSON.stringify(dynamicFields));
      dynamicFields_?.forEach((fieldInfo: any) => {
        fieldInfo?.field_data?.forEach((fields: any) => {
          if (!fields.field_value) {
            fields.field_value = `{${fields.field}}`;
          }
          if (
            fields.data_type.toLowerCase() === 'boolean' ||
            fields.data_type.toLowerCase() === 'bulleted list' ||
            fields.data_type.toLowerCase() === 'numbered list'
          ) {
            fields.field_value = fields.field_value
              ? JSON.stringify(fields.field_value)
              : `{${fields.field}}`;
          }
        });
      });
      setCompleteDraftFormData?.(dynamicFields_);
    }
  }, [dynamicFields, setCompleteDraftFormData]);

  const highlightTag = useCallback(
    (tag: string) => {
      if (instance) {
        const { Core } = instance;
        const { documentViewer, Annotations, annotationManager } = Core;
        const annotations = annotationManager.getAnnotationsList();
        annotationManager.deleteAnnotations(annotations);
        const searchData = '{' + tag + '}';
        const searchMode =
          instance.Core.Search.Mode.REGEX |
          instance.Core.Search.Mode.HIGHLIGHT |
          instance.Core.Search.Mode.PAGE_STOP;
        let isMoveToPage = true;
        const searchOptions = {
          fullSearch: true,
          onResult: (result: any) => {
            if (result.resultCode === instance.Core.Search.ResultCode.FOUND) {
              const highlight = new Annotations.TextHighlightAnnotation();
              result.quads.map((quad: { getPoints: () => any }) => {
                highlight.Quads = [quad.getPoints()] as any[];
              });
              highlight.PageNumber = result.pageNum;
              highlight.X = 405;
              highlight.Y = 165;
              highlight.Width = 275;
              highlight.Height = 25;
              annotationManager.addAnnotation(highlight);
              annotationManager.redrawAnnotation(highlight);
              annotationManager.drawAnnotations({ pageNumber: result.pageNum });
              if (documentViewer.getCurrentPage() === result.pageNum) {
                isMoveToPage = false;
              }
              if (isMoveToPage) {
                documentViewer.setCurrentPage(result.pageNum, true);
              }
            }
          },
        };
        documentViewer.textSearchInit(searchData, searchMode, searchOptions);
      }
    },
    [instance]
  );

  const updateListData = (
    event: any,
    sectionIndex: number,
    fieldIndex: number,
    itemIndex: number
  ) => {
    const fields = [...dynamicFields];
    fields[sectionIndex].field_data[fieldIndex].field_value[itemIndex].list =
      event.target.value;
    setDynamicFields(fields);
  };

  const addItemList = (
    event: any,
    sectionIndex: number,
    fieldIndex: number,
    ItemIndex: number
  ) => {
    event.preventDefault();
    const fields = [...dynamicFields];
    if (
      fields[sectionIndex].field_data[fieldIndex].field_value[ItemIndex][
        'list'
      ] === ''
    )
      return;
    fields[sectionIndex].field_data[fieldIndex].field_value.push({ list: '' });
    setDynamicFields(fields);
  };

  const removeItemFromField = (
    sectionIndex: number,
    fieldIndex: number,
    index: number
  ) => {
    const fields = [...dynamicFields];
    fields[sectionIndex].field_data[fieldIndex].field_value.splice(index, 1);
    setDynamicFields(fields);
  };

  const updateFieldReminderIds = useMemo(() => {
    return dynamicFields
      .flatMap((fields: any) =>
        fields?.field_data
          ?.filter((data: any) => data?.field_value !== '')
          .flatMap((data: any) => data?.reminder_id || [])
      )
      .filter(Boolean);
  }, [dynamicFields]);

  const onSubmit = React.useCallback(() => {
    handleSaveDraft();
    if (updateFieldReminderIds.length > 0) {
      const updateChecklistPayload = {
        ids: updateFieldReminderIds,
        status: 'Completed',
      };
      update_bulk_reminders(updateChecklistPayload);
    }
  }, [handleSaveDraft, updateFieldReminderIds]);

  const isDisabled = (email: string) => {
    if (userIsOwner || userIsCreator) {
      return false;
    } else if (
      (user_data?.email && email === user_data?.email) ||
      (external_email && external_email === email)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const sectionUserCounts = useMemo(() => {
    return dynamicFields?.reduce(
      (acc: any, section: any) => {
        const userCount =
          section?.field_data?.filter(
            (data: any) =>
              data?.email === user_data?.email &&
              (data?.field_value === '' || data?.field_value === null)
          ).length || 0;

        return {
          ...acc,
          [section?.section_name]: userCount,
        };
      },
      {} as Record<string, number>
    );
  }, [dynamicFields, user_data?.email]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form id="complete_draft_form" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={1} margin="15px">
            {dynamicFields?.map((section: any, sectionIndex: number) => (
              <Accordion
                sx={{
                  background: '#88305F24',
                  boxShadow: 'none',
                }}
                key={`${section.section_name}-${sectionIndex}`}
                expanded={expanded === section.section_id}
                onChange={handleAccordionChange(section.section_id)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography>{section?.section_name}</Typography>
                    {expanded !== section?.section_id &&
                      section?.field_data?.some(
                        (data: any) => data?.email === user_data?.email
                      ) && (
                        <Stack direction="row">
                          <NameAvatar
                            firstName={user_data?.first_name}
                            lastName={user_data?.last_name}
                            sx={{
                              color: '#A0597F',
                              border: '3px solid #A0597F',
                              backgroundColor: 'unset',
                              width: 24,
                              height: 24,
                              fontSize: '12px',
                            }}
                          />
                          {sectionUserCounts[section?.section_name] > 0 && (
                            <Typography
                              variant="caption"
                              sx={{
                                borderRadius: '50%',
                                height: 18,
                                width: 18,
                                textAlign: 'center',
                                margin: '-6px 0 0 -10px',
                                background: '#A0597F',
                                color: '#fff',
                                zIndex: 1,
                              }}
                            >
                              {sectionUserCounts[section.section_name]}
                            </Typography>
                          )}
                        </Stack>
                      )}
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" spacing={2}>
                    {section?.field_data?.map(
                      (fieldData: any, fieldIndex: number) => {
                        const fullName: string[] =
                          fieldData?.user_name?.split(' ');
                        return (
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                            key={`${fieldData.field_name}-${fieldIndex}`}
                          >
                            {(fieldData?.data_type.toLowerCase() === 'text' ||
                              fieldData?.data_type.toLowerCase() ===
                                'numeric' ||
                              fieldData?.data_type.toLowerCase() ===
                                'text box' ||
                              fieldData?.data_type.toLowerCase() ===
                                'currency verbal' ||
                              fieldData?.data_type.toLowerCase() ===
                                'date verbal') && (
                              <ControlledTextField
                                key={fieldData?.id}
                                defaultValue={fieldData?.field_value}
                                control={control}
                                name={fieldData?.field_name}
                                label={fieldData?.display_name}
                                disabled={isDisabled(fieldData?.email)}
                                variant="outlined"
                                fullWidth
                                type={fieldData?.data_type.toLowerCase()}
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    'text'
                                  )
                                }
                                required={fieldData?.required}
                                onFocus={() => {
                                  highlightTag(fieldData.field_name);
                                }}
                                InputProps={{
                                  readOnly: isTemplateView,
                                }}
                              />
                            )}
                            {fieldData?.data_type.toLowerCase() === 'date' && (
                              <DatePicker
                                sx={{ width: '100%' }}
                                label={fieldData.display_name}
                                value={dayjs(fieldData.field_value)}
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    'date'
                                  )
                                }
                                slotProps={{
                                  textField: {
                                    required: fieldData.required,
                                    onFocus: () =>
                                      highlightTag(fieldData.field_name),
                                  },
                                }}
                                format="MM-DD-YYYY"
                                readOnly={isTemplateView}
                                disabled={isDisabled(fieldData?.email)}
                              />
                            )}
                            {(fieldData?.data_type.toLowerCase() ===
                              'percentage' ||
                              fieldData?.data_type.toLowerCase() ===
                                'currency') && (
                              <ControlledTextField
                                key={fieldData?.id}
                                control={control}
                                name={fieldData?.field_name}
                                label={fieldData?.display_name}
                                variant="outlined"
                                fullWidth
                                type="number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {fieldData?.data_type.toLowerCase() ===
                                        'percentage' && <PercentIcon />}
                                      {fieldData?.data_type.toLowerCase() ===
                                        'currency' &&
                                        currency_icon[
                                          fieldData?.set_recommended_value
                                            ?.default_field_unit
                                        ]}
                                    </InputAdornment>
                                  ),
                                  readOnly: isTemplateView,
                                }}
                                onFocus={() => {
                                  highlightTag(fieldData.field_name);
                                }}
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    fieldData?.data_type.toLowerCase()
                                  )
                                }
                                required={fieldData?.required}
                                disabled={isDisabled(fieldData?.email)}
                              />
                            )}

                            {fieldData?.data_type.toLowerCase() ===
                              'duration' && (
                              <Stack direction="row" spacing={1}>
                                <ControlledTextField
                                  key={fieldData?.id}
                                  control={control}
                                  name={fieldData?.field_name}
                                  label={fieldData?.display_name}
                                  type="number"
                                  sx={{ flex: 1 }}
                                  inputProps={{
                                    readOnly: isTemplateView,
                                  }}
                                  onChange={(event: any) =>
                                    updateDynamicFieldValue(
                                      event,
                                      sectionIndex,
                                      fieldIndex,
                                      'text'
                                    )
                                  }
                                  required={fieldData?.required}
                                  onFocus={() => {
                                    highlightTag(fieldData.field_name);
                                  }}
                                  disabled={isDisabled(fieldData?.email)}
                                />
                                <Select
                                  value={fieldData.unit || 'Days'}
                                  label="Duration"
                                  variant="outlined"
                                  required={fieldData?.required}
                                  inputProps={{
                                    id: 'Select-duration',
                                  }}
                                  readOnly={isTemplateView}
                                  onChange={(event: any) =>
                                    updateDynamicFieldValue(
                                      event,
                                      sectionIndex,
                                      fieldIndex,
                                      'duration'
                                    )
                                  }
                                  onFocus={() => {
                                    highlightTag(fieldData.field_name);
                                  }}
                                  disabled={isDisabled(fieldData?.email)}
                                >
                                  <MenuItem key={'Days'} value={'Days'}>
                                    Day(s)
                                  </MenuItem>
                                  <MenuItem key={'Months'} value={'Months'}>
                                    Month(s)
                                  </MenuItem>
                                  <MenuItem key={'Years'} value={'Years'}>
                                    Years(s)
                                  </MenuItem>
                                </Select>
                              </Stack>
                            )}
                            {fieldData.data_type.toLowerCase() === 'select' && (
                              <FormControl fullWidth>
                                <InputLabel id="select_textfield">
                                  {fieldData?.display_name}
                                </InputLabel>
                                <Select
                                  labelId="select_textfield"
                                  value={fieldData.field_value}
                                  label={fieldData?.display_name}
                                  variant="outlined"
                                  required={fieldData?.required}
                                  readOnly={isTemplateView}
                                  onChange={(event: any) =>
                                    updateDynamicFieldValue(
                                      event,
                                      sectionIndex,
                                      fieldIndex,
                                      'select'
                                    )
                                  }
                                  onFocus={() => {
                                    highlightTag(fieldData.field_name);
                                  }}
                                  disabled={isDisabled(fieldData?.email)}
                                >
                                  {fieldData?.set_recommended_value?.default_field_value.map(
                                    (item: any) => (
                                      <MenuItem
                                        key={item.name}
                                        value={item.name}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            )}
                            {fieldData.data_type.toLowerCase() ===
                              'boolean' && (
                              <ControlledCheckbox
                                name={fieldData?.field_name}
                                label={fieldData?.display_name}
                                required={fieldData?.required}
                                control={control}
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    'boolean'
                                  )
                                }
                                onFocus={() => {
                                  highlightTag(fieldData.field_name);
                                }}
                                inputProps={{
                                  readOnly: isTemplateView,
                                }}
                                disabled={isDisabled(fieldData?.email)}
                              />
                            )}
                            {(fieldData.data_type.toLowerCase() ===
                              'bulleted list' ||
                              fieldData.data_type.toLowerCase() ===
                                'numbered list') && (
                              <Stack spacing={1} mb={3}>
                                {fieldData?.field_value?.length > 0 &&
                                  fieldData?.field_value.map(
                                    (
                                      item: any,
                                      index: number,
                                      items: any[]
                                    ) => (
                                      <Stack direction="row" key={index}>
                                        <TextField
                                          key={fieldData?.id}
                                          value={item.list}
                                          label={fieldData?.display_name}
                                          variant="outlined"
                                          fullWidth
                                          inputProps={{
                                            readOnly: isTemplateView,
                                          }}
                                          type="text"
                                          onChange={(event: any) =>
                                            updateListData(
                                              event,
                                              sectionIndex,
                                              fieldIndex,
                                              index
                                            )
                                          }
                                          required={fieldData?.required}
                                          onFocus={() => {
                                            highlightTag(fieldData.field_name);
                                          }}
                                          disabled={isDisabled(
                                            fieldData?.email
                                          )}
                                        />
                                        {items.length - 1 === index && (
                                          <IconButton
                                            disabled={isDisabled(
                                              fieldData?.email
                                            )}
                                            onClick={(e) =>
                                              addItemList(
                                                e,
                                                sectionIndex,
                                                fieldIndex,
                                                index
                                              )
                                            }
                                          >
                                            <AddCircleOutline />
                                          </IconButton>
                                        )}
                                        {items.length - 1 !== index && (
                                          <IconButton
                                            disabled={isDisabled(
                                              fieldData?.email
                                            )}
                                            onClick={() =>
                                              removeItemFromField(
                                                sectionIndex,
                                                fieldIndex,
                                                index
                                              )
                                            }
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        )}
                                      </Stack>
                                    )
                                  )}
                              </Stack>
                            )}
                            {fieldData?.user_name && (
                              <Stack direction="row" alignItems="center">
                                <IconButton
                                  edge="end"
                                  onClick={() =>
                                    handleOpenAssigneeDialog(fieldData)
                                  }
                                >
                                  <NameAvatar
                                    firstName={fullName?.[0]}
                                    lastName={fullName?.[1]}
                                  />
                                </IconButton>

                                {!fieldData?.reminder?.due_date &&
                                  userIsOwner && (
                                    <Tooltip title="Create Reminder">
                                      <EventIcon
                                        onClick={() =>
                                          handleOpenAssigneeDialog(fieldData)
                                        }
                                        sx={{
                                          color: '#88305F',
                                          fontSize: '20px',
                                          cursor: 'pointer',
                                          marginLeft: '10px',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                              </Stack>
                            )}
                            {!isTemplateView && !fieldData?.user_name && (
                              <IconButton
                                edge="end"
                                disabled={!userIsOwner && !userIsCreator}
                                onClick={() =>
                                  handleOpenAssigneeDialog(fieldData)
                                }
                              >
                                <PersonAddAltOutlinedIcon />
                              </IconButton>
                            )}
                          </Stack>
                        );
                      }
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        </form>
      </FormProvider>

      {openAssigneeDialog && (
        <AssigneeModal
          open={openAssigneeDialog}
          onClose={handleCloseAssigneeDialog}
          approversData={approversData}
          title="Assign this template field to someone"
          heading="Assign this template field to"
          handleAssignee={handleAssignee}
          handleReminder={handleReminder}
          assigneeDialogPropData={assigneeDialogPropData}
          completeDraftReminder={
            complete_reminder_data?.results?.[0] || reminderData
          }
          handleUpdateReminder={handleUpdateReminder}
          draftData={draftData}
          optionalFields={optionalFields}
          isExternal={isExternal}
          updateDraftData={updateDraftData}
        />
      )}
    </React.Fragment>
  );
};

export default CompleteDraft;
