import React, { useState } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import DeleteIcon from '../RiverusUI/Components/Icons/DeleteIcon';
import ReusableConfirmationModal from '../RiverusUI/Components/ReusableConfirmationModal';
import { deleteStamp } from '../Services/Stamp';

interface Props {
  data: any;
  isLoading: boolean;
  showAction?: boolean;
}

const StampTable = (props: Props) => {
  const { data, isLoading, showAction = true } = props;

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteDocId, setDeleteDocId] = useState<string>('');

  const handleOpenDeleteModal = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteDocId(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteDocId('');
    setOpenDeleteModal(false);
  };

  const { mutate: deleteStampMutation } = useMutation({
    mutationKey: ['delete-stamp'],
    mutationFn: deleteStamp,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stamp-bank-list'] });
      queryClient.invalidateQueries({ queryKey: ['stamp-bank'] });
      queryClient.invalidateQueries({ queryKey: ['stamp-by-state'] });
      enqueueSnackbar({
        message: 'Stamp deleted successfully!',
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleCloseDeleteModal();
    },
  });

  const columns = React.useMemo<GridColDef<any>[]>(() => {
    let col = [
      {
        field: 'serial_number',
        headerName: 'Serial Number',
        minWidth: 80,
        flex: 1,
        sortable: false,
      },
      {
        field: 'denomination',
        headerName: 'Value',
        flex: 1,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 80,
        flex: 1,
        sortable: false,
      },
      {
        field: 'contractName',
        headerName: 'Assigned draft',
        minWidth: 120,
        flex: 1,
        sortable: false,
        renderCell: (params: any) => (
          <Typography>{params?.row?.draft?.contractName || '-'}</Typography>
        ),
      },
      {
        field: 'used_on',
        headerName: 'Used On',
        minWidth: 80,
        flex: 1,
        sortable: false,
        renderCell: (params: any) => (
          <Typography>{params?.row?.used_on || '-'}</Typography>
        ),
      },
      {
        field: 'id',
        headerName: 'Action',
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <IconButton onClick={() => handleOpenDeleteModal(params.id)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ];
    if (!showAction) {
      col = col.filter(
        (data: any) =>
          data.headerName !== 'Action' && data.headerName !== 'Assigned draft'
      );
    }
    return col;
  }, [deleteStampMutation, showAction]);

  const getHeight = () => {
    const length = data?.results.length;
    switch (length) {
      case 1:
        return 130;
      case 2:
        return 180;
      case 3:
        return 250;
      case 4:
        return 300;
      default:
        return 350;
    }
  };

  if (data?.results.length) {
    return (
      <Box sx={{ height: getHeight(), width: '100%' }}>
        <DataGrid
          rows={data?.results || []}
          columns={columns}
          disableRowSelectionOnClick
          rowCount={data?.count || 0}
          loading={isLoading}
          disableColumnMenu
          hideFooter
          slots={{
            loadingOverlay: LinearProgress,
          }}
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '&.MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'riPrimary.10',
              fontSize: 16,
              borderRadius: '50px',
              borderBottom: 'none',
            },
          }}
        />
        {openDeleteModal && (
          <ReusableConfirmationModal
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            title="Delete Document"
            cancelBtnText="No, Go Back"
            confirmBtnText="Yes, Delete"
            onConfirm={() => deleteStampMutation(deleteDocId)}
          >
            <Stack spacing={2}>
              <Typography>Are you sure?</Typography>
              <Typography>
                The selected document will be removed permanently.
              </Typography>
              <Typography>Note: This action is not reversible.</Typography>
            </Stack>
          </ReusableConfirmationModal>
        )}
      </Box>
    );
  }
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      style={{ color: '#938F99' }}
    >
      <InfoOutlinedIcon />
      <Typography margin={0}>No stamp-papers available</Typography>
    </Stack>
  );
};

export default StampTable;
