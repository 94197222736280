import React, { useEffect, useState } from 'react';

import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import FilterInput from './FilterInput';
import ReminderColumn from './ReminderColumn';
import { appOption } from './ReminderStaticData';
import CustomChip from '../Approvals/Component/CreateApprovalForm/CustomChip';
import { getKeyCloakRealmFromLS } from '../Authentication/Actions/authentication';
import ShareChip from '../DocumentLibrary/Component/ShareModal/ShareChip';
import { reminderStatus } from '../DocumentView/Component/Reminders/ReminderStatic';
import { fetchCollaborators } from '../Services/Draft';
import { fetchGroups, fetchReminders } from '../Services/Reminder';

interface props {
  pageWatcher: (page: string) => void;
}

const ReminderComponent: React.FC<props> = ({ pageWatcher }) => {
  const { control, watch } = useForm();
  const realm_name = getKeyCloakRealmFromLS();

  const filterApp = watch('filter_by_app') || '';
  const filterGroup = watch('filter_by_group') || '';
  const filterUsers = watch('filter_by_user') || '';
  const filterStatus = watch('filter_by_status') || '';

  const [isShowExpired, setIsShowExpired] = useState<boolean>(true);
  const [filterParams, setFilterParams] = useState<string>('');

  const { data: groupData, isLoading: groupLoading } = useQuery({
    queryKey: ['group_data'],
    queryFn: async () => await fetchGroups(),
  });

  useEffect(() => {
    pageWatcher('reminders');
  }, [pageWatcher]);

  useEffect(() => {
    let filterParams = '';

    if (filterApp) {
      filterParams = `&app=${filterApp}`;
    }

    if (filterGroup?.length > 0) {
      filterParams = `${filterParams}&groups=${filterGroup.join(',')}`;
    }

    if (filterUsers?.length > 0) {
      const users = filterUsers?.map(
        (item: string) => `${realm_name}__${item}`
      );
      filterParams = `${filterParams}&users=${users.join(',')}`;
    }

    if (filterStatus?.length > 0) {
      filterParams = `${filterParams}&status=${filterStatus.join(',')}`;
    }

    setFilterParams(filterParams);
  }, [filterApp, filterGroup, filterUsers, filterStatus]);

  const { data: approversData, isLoading: approversLoading } = useQuery({
    queryKey: ['Approvers'],
    queryFn: async () => {
      const response = await fetchCollaborators();
      const groups = response.results.map((data: any) => ({
        ...data,
        name: `${data.first_name} ${data.last_name}`,
      }));
      return groups;
    },
  });

  const { data: urgentReminderList, isLoading: isUrgentLoading } = useQuery({
    queryKey: ['get_urgent_reminder_list', filterParams, isShowExpired],
    queryFn: async () => {
      const params = `${
        isShowExpired ? 'priority=High' : 'urgent_expired=true'
      }${filterParams}`;
      const response = await fetchReminders(params);
      return response?.results;
    },
  });

  const { data: weekReminderList, isLoading: isWeekLoading } = useQuery({
    queryKey: ['get_this_week_reminder_list', filterParams, isShowExpired],
    queryFn: async () => {
      const params = `${
        isShowExpired ? 'this_week=true' : 'week_expired=true'
      }${filterParams}`;

      const response = await fetchReminders(params);
      return response?.results;
    },
  });

  const { data: monthReminderList, isLoading: isMonthLoading } = useQuery({
    queryKey: ['get_this_month_reminder_list', filterParams, isShowExpired],
    queryFn: async () => {
      const params = `${
        isShowExpired ? 'this_month=true' : 'month_expired=true'
      }${filterParams}`;
      const response = await fetchReminders(params);
      return response?.results;
    },
  });

  const { data: upcomingReminderList, isLoading: isUpcomingLoading } = useQuery(
    {
      queryKey: ['get_this_upcoming_reminder_list', filterParams],
      queryFn: async () => {
        const params = `upcoming=true${filterParams}`;
        const response = await fetchReminders(params);
        return response?.results;
      },
    }
  );

  const { data: expiredReminderList, isLoading: isExpiredLoading } = useQuery({
    queryKey: ['get_this_expired_reminder_list', filterParams],
    queryFn: async () => {
      const params = `expired=true${filterParams}`;
      const response = await fetchReminders(params);
      return response?.results;
    },
  });

  return (
    <Box p={5}>
      <Stack gap="16px">
        <Typography fontWeight={700}>Filters</Typography>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={2.3}>
            <FilterInput
              name="filter_by_app"
              control={control}
              label="Filter by app"
              options={appOption}
              renderCustomComponent={(value: any, props) => (
                <CustomChip {...props} label={value?.name} />
              )}
            />
          </Grid>
          <Grid item xs={2.3}>
            <FilterInput
              name="filter_by_group"
              control={control}
              label="Filter by group"
              options={groupData}
              loading={groupLoading}
              isMultiselect={true}
              valueKey="name"
              renderCustomComponent={(value: any, props) => (
                <CustomChip
                  {...props}
                  icon={
                    <GroupWorkOutlinedIcon
                      style={{
                        color: '#6D264C',
                      }}
                    />
                  }
                  label={value?.name}
                />
              )}
            />
          </Grid>
          <Grid item xs={2.3}>
            <FilterInput
              name="filter_by_user"
              control={control}
              label="Filter by user"
              options={approversData}
              loading={approversLoading}
              isMultiselect={true}
              valueKey="email"
              renderCustomComponent={(value: any, props) => (
                <ShareChip key={value?.id} {...props} label={value?.name} />
              )}
            />
          </Grid>
          <Grid item xs={2.3}>
            <FilterInput
              name="filter_by_status"
              control={control}
              label="Filter by status"
              options={reminderStatus}
              isMultiselect={true}
              renderCustomComponent={(value: any, props) => (
                <CustomChip {...props} label={value?.name} />
              )}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Button
              variant="text"
              style={{
                padding: '10px',
              }}
              startIcon={
                isShowExpired ? <VisibilityOffIcon /> : <VisibilityIcon />
              }
              onClick={() => setIsShowExpired(!isShowExpired)}
            >
              {isShowExpired ? 'Hide' : 'Show'} expired reminders
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1} flexWrap="unset">
          {filterApp !== 'Umbrella' && (
            <Grid item xs={2.9}>
              <Typography fontWeight={700}>Urgent</Typography>
              <ReminderColumn
                reminderList={urgentReminderList}
                isLoading={isUrgentLoading}
              />
            </Grid>
          )}
          <Grid item xs={2.9}>
            <Typography fontWeight={700}>This week</Typography>
            <ReminderColumn
              reminderList={weekReminderList}
              isLoading={isWeekLoading}
            />
          </Grid>
          <Grid item xs={2.9}>
            <Typography fontWeight={700}>This month</Typography>
            <ReminderColumn
              reminderList={monthReminderList}
              isLoading={isMonthLoading}
            />
          </Grid>
          {filterApp !== 'Stylus' && (
            <Grid item xs={2.9}>
              <Typography fontWeight={700}>Reminders coming later</Typography>
              <ReminderColumn
                reminderList={upcomingReminderList}
                isLoading={isUpcomingLoading}
              />
            </Grid>
          )}
          {isShowExpired && (
            <Grid item xs={2.9}>
              <Typography fontWeight={700}>Expired reminders</Typography>
              <ReminderColumn
                reminderList={expiredReminderList}
                isLoading={isExpiredLoading}
              />
            </Grid>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};

export default ReminderComponent;
