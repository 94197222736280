import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';

import { status_bg_color } from './ReminderStaticData';
import CustomChip from '../Approvals/Component/CreateApprovalForm/CustomChip';
import { getSessionStorage } from '../Authentication/Actions/authentication';
import {
  iconColor,
  status_icon,
} from '../DocumentView/Component/Reminders/ReminderStatic';
import ListSkeleton from '../RiverusUI/Components/Skeleton/ListSkeleton';
import NameAvatar from '../RiverusUI/DataGrid/NameAvatar';
import { getDraftById } from '../Services/Draft';

interface Props {
  isLoading: boolean;
  reminderList: any;
}

const ReminderColumn: React.FC<Props> = ({ reminderList, isLoading }) => {
  const user_data = React.useMemo(() => getSessionStorage('user_profile'), []);

  const handleRouting = (item: any) => {
    const is_owner = item?.created_by?.id === user_data?.id;
    const isAssignee = item?.users?.find(
      (item: any) => item?.id === user_data?.id
    );
    if (is_owner || isAssignee) {
      window.open(
        '/document/documentlibrary/' +
          btoa(item?.contract?.id?.toString()) +
          '?isReminder=true&reminderId=' +
          item?.id,
        '_blank'
      );
    } else {
      enqueueSnackbar('You are not assigned any role for this reminder!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const isOwner = React.useCallback(
    (owners: any) => {
      const index = owners.findIndex(
        (owner: any) => owner?.id === user_data?.id
      );
      if (index > -1) {
        return true;
      }
      return false;
    },
    [user_data]
  );

  const canAccessReminder = React.useCallback(
    (data: any, reminderData: any) => {
      const assignedUser = reminderData?.users?.filter(
        (item: any) => item?.id === user_data?.id
      );
      const owner = isOwner(data?.owners);
      if (owner || assignedUser?.length) {
        if (
          data?.status === 'Completed' ||
          (data?.new_draft_exists && data?.reminder_type !== 'checklist')
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    [user_data, isOwner]
  );

  const handleReminderNameClick = React.useCallback(
    async (item: any) => {
      const draftResponse = await getDraftById(
        item?.new_draft_id || item?.draft?.id
      );

      if (
        item?.status === 'Completed' ||
        (item?.new_draft_exists && item?.reminder_type !== 'checklist') ||
        item?.status === 'contract executed successfully'
      ) {
        enqueueSnackbar('Reminder no longer exists!', {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        return;
      }

      if (draftResponse) {
        const accessGranted = canAccessReminder(draftResponse, item);
        if (accessGranted && !item?.new_draft_id) {
          window.open(
            `/draft/${draftResponse?.version}/${draftResponse?.id}/?reminder_type=${item?.reminder_type}&reminderId=${item?.id}`,
            '_blank'
          );
        } else if (item?.new_draft_id) {
          window.open(
            `/draft/${item?.new_draft_version}/${item?.new_draft_id}/?reminder_type=${item?.reminder_type}&reminderId=${item?.id}`,
            '_blank'
          );
        } else {
          enqueueSnackbar('You are not assigned any role for this reminder!', {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
    },
    [canAccessReminder]
  );

  return (
    <Stack
      spacing={1}
      sx={{
        minHeight: '100%',
        backgroundColor: 'riTertiary.50',
        borderRadius: '20px',
        margin: '16px 0',
        padding: '16px',
        gap: '16px',
      }}
    >
      {isLoading && (
        <Box px={2}>
          <ListSkeleton />
        </Box>
      )}

      {reminderList?.length === 0 && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          style={{ color: '#938F99' }}
        >
          <InfoOutlinedIcon />
          <Typography>No reminder found</Typography>
        </Stack>
      )}

      {reminderList?.map((item: any, index: number) => {
        const isPastDue = dayjs(item?.due_date).isBefore(dayjs(), 'day');
        return (
          <Stack
            key={index}
            className="according-class"
            padding="16px"
            spacing={0}
            alignItems="start"
            sx={{
              cursor: 'pointer',
              mt: '0 !important',
              gap: '8px',
            }}
            onClick={() => {
              if (item?.app === 'Umbrella') {
                handleRouting(item);
              } else if (item?.app === 'Stylus') {
                handleReminderNameClick(item);
              }
            }}
          >
            <Typography>{item?.name}</Typography>
            <Stack
              direction="row"
              spacing={1}
              width="100%"
              sx={{
                flexFlow: 'wrap',
                gap: '8px',
              }}
            >
              <CustomChip
                label={item?.app}
                sx={{
                  background: '#CFACBF',
                  padding: '6px 16px',
                  borderRadius: '5px',
                  border: '1px solid #79747E',
                  '& .MuiChip-label': {
                    padding: '0',
                  },
                  '&:hover': {
                    background: '#fff',
                  },
                }}
              />
              <CustomChip
                sx={{
                  background: status_bg_color?.[item?.status],
                  padding: '6px 8px',
                  borderRadius: '5px',
                  border: '1px solid #79747E',
                  marginLeft: '0 !important',
                  '& .MuiChip-label': {
                    paddingRight: '0',
                    paddingLeft: '4px',
                    fontSize: '12px',
                  },
                  '& .MuiChip-icon': {
                    width: '14px',
                    height: '14px',
                    margin: '0',
                    color: iconColor[item?.status],
                  },
                  '&:hover': {
                    background: '#fff',
                  },
                }}
                icon={status_icon[item?.status]}
                label={item?.status}
              />
            </Stack>
            <CustomChip
              sx={{
                background: isPastDue ? '#F9DEDC' : '#FFECF1',
                padding: '6px 8px',
                borderRadius: '5px',
                border: isPastDue ? '1px solid #B3261E' : '',
                '& .MuiChip-label': {
                  paddingRight: '0',
                  paddingLeft: '4px',
                  fontSize: '12px',
                },
                '& .MuiChip-icon': {
                  width: '14px',
                  height: '14px',
                  margin: '0',
                  color: isPastDue ? '#7B0D0D' : '',
                },
                '&:hover': {
                  background: isPastDue ? '#e8c0bd' : '#ebe8e8',
                },
              }}
              icon={<AccessTimeIcon />}
              label={dayjs(item?.due_date).format('MMMM D, YYYY')}
            />
            {item?.contract?.file_name && (
              <Typography fontSize="13px">
                {item?.contract?.file_name}
              </Typography>
            )}
            {item?.support_documents?.length > 0 && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography fontWeight={600} fontSize="14px">
                  Support documents:
                </Typography>
                <Typography fontSize="13px">
                  {item?.support_documents?.length}
                </Typography>
              </Stack>
            )}
            <Stack direction="row" spacing={1} sx={{ mt: '6px !important' }}>
              {item?.external_user?.length > 0
                ? item.external_user.map((userItem: any) => {
                    const fullName: string[] = userItem?.name?.split(' ');
                    return (
                      <NameAvatar
                        key={userItem?.id}
                        firstName={fullName?.[0]}
                        lastName={fullName?.[1]}
                        sx={{
                          color: '#A0597F',
                          border: '3px solid #A0597F',
                          backgroundColor: 'unset',
                          width: 32,
                          height: 32,
                          fontSize: '12px',
                        }}
                      />
                    );
                  })
                : item?.users?.map((userItem: any) => (
                    <NameAvatar
                      key={userItem?.id}
                      firstName={userItem?.first_name}
                      lastName={userItem?.last_name}
                    />
                  ))}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ReminderColumn;
