import React, { useEffect, useMemo, useState } from 'react';

import { useKeycloak } from '@react-keycloak/web';

import RedirectToKeycloak from './RedireactToKeycloak';
import {
  getKeyCloakRealmFromLS,
  getLocalStorage,
  removeKeyCloakRealmOnLS,
} from '../../Authentication/Actions/authentication';
import BarLoader from '../../UniversalComponents/Loader/barLoader';

interface IProps {
  handleKeycloakRealmNameChange: (realmName: string) => void;
  children: React.ReactNode;
}

const PrivateRoute: React.FC<IProps> = ({
  handleKeycloakRealmNameChange,
  children,
}) => {
  const { keycloak } = useKeycloak();
  const keycloakRealmName = getKeyCloakRealmFromLS();
  const isLoggedIn = keycloak.authenticated;
  const [loadingTime, setLoadingTime] = useState(0);

  const handleKeycloakRedirection = () => {
    if (keycloak && !isLoggedIn) {
      keycloak.login();
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      //do nothing
    } else if (loadingTime === 1) {
      removeKeyCloakRealmOnLS();
      window.location.reload();
    }
  }, [isLoggedIn, loadingTime]);

  const accessToken = useMemo(() => getLocalStorage('accessToken'), []);

  if (isLoggedIn && accessToken !== undefined) {
    return <div>{children}</div>;
  } else if (isLoggedIn === false) {
    return (
      <RedirectToKeycloak
        keycloakRealmName={keycloakRealmName}
        handleKeycloakRedirection={handleKeycloakRedirection}
        handleKeycloakRealmNameChange={handleKeycloakRealmNameChange}
      />
    );
  } else {
    setTimeout(() => {
      setLoadingTime(1);
    }, 10000);

    return <BarLoader />;
  }
};

export default PrivateRoute;
