import { authApi } from './authApi';

export const fetchDictionaryContractType = async (
  params: any
): Promise<any> => {
  return await authApi.get(`admin/contracttype/${params}`);
};

export const fetchDictionaryContractTypeById = async (
  id: string
): Promise<any> => {
  return await authApi.get(`admin/contracttype/${id}/`);
};

export const addDictionaryContractType = async (payload: any): Promise<any> => {
  return await authApi.post('admin/contracttype/', payload);
};

export const fetchAdminContractList = async (): Promise<any> => {
  return await authApi.get('admin/contracttype/contract-create-custom/');
};

export const addAdminContract = async (payload: any) => {
  return await authApi.post(
    'admin/contracttype/contract-create-custom/',
    payload
  );
};

export const fetchContractList = async (): Promise<any> => {
  return await authApi.get('admin/contracttype/contract-type/');
};

export const addContract = async (payload: any) => {
  return await authApi.post('admin/contracttype/contract-type/', payload);
};

export const fetchFields = async (id: string): Promise<any> => {
  return await authApi.get(`data-dictionary/get_field?contract_type=${id}`);
};

export const addSections = async (payload: any): Promise<any> => {
  return await authApi.post('data-dictionary/section_dictionary/', payload);
};

export const reorderSections = async (payload: any) => {
  if (payload.sorted) {
    return await authApi.patch(
      `data-dictionary/section_order/${payload.id}/`,
      payload.body
    );
  } else {
    const postPayload = {
      ...payload.body,
      contract_type: payload.id,
    };
    return await authApi.post(`data-dictionary/section_order/`, postPayload);
  }
};

export const getDataTypeList = async (): Promise<any> => {
  return await authApi.get('data-dictionary/data_type/');
};

export const getAdminTags = async (type: string): Promise<any> => {
  return await authApi.get(`data-dictionary/tags/?tag_type=${type}`);
};

export const addAdminTags = async (payload: any) => {
  return await authApi.post('data-dictionary/tags/', payload);
};

export const addFields = async (payload: any) => {
  if (payload.id) {
    return await authApi.patch(
      `data-dictionary/field_names/${payload.id}/`,
      payload.body
    );
  } else {
    return await authApi.post('data-dictionary/field_names/', payload.body);
  }
};

export const deleteField = async (id: string) => {
  return await authApi.delete(`data-dictionary/field_names/${id}/`);
};

export const updateSection = async (payload: any) => {
  return await authApi.patch(
    `data-dictionary/section_dictionary/${payload.id}/`,
    payload.data
  );
};

export const deleteDataDictionary = async (payload: any): Promise<any> => {
  return await authApi.delete(`admin/contracttype/`, { data: payload });
};
