import React, { Dispatch, SetStateAction, useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Stack, Typography } from '@mui/material';
import { GridColDef, GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isArray } from 'lodash-es';
import { useDebouncedCallback } from 'use-debounce';

import ClauseTableTooltip from './ClauseTableTooltip';
import { getContractsTags } from '../../Draft/DA';
import { IObject } from '../../interfaces/IObject';
import DataGridTable from '../../RiverusUI/DataGrid/DataGridTable';
import NameAvatar from '../../RiverusUI/DataGrid/NameAvatar';
import TableAsyncSelectInput from '../../RiverusUI/DataGrid/TableAsyncSelectInput';
import TableChipList from '../../RiverusUI/DataGrid/TableChipList';
import TableHeaderWithSearch from '../../RiverusUI/DataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../../RiverusUI/DataGrid/TableSearchInputComponent';
import { getUsers } from '../../Services/Approval';
import { fetchContractList } from '../../Services/dictionary';
import CreateNewClauseModal from '../../UniversalComponents/Modals/ClauseSectionModal/CreateNewClauseModal';

interface Props {
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  setSorting?: Dispatch<SetStateAction<GridSortModel>>;
  filters?: IObject;
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  selectedRowData: any;
  setSelectedRowData: any;
  sectionNamesData?: any;
}

const ClauseTable: React.FC<Props> = ({
  data,
  isLoading,
  pageNumber,
  setPageNumberChange,
  setFilters,
  setSorting,
  filters,
  setSelectedRow,
  selectedRowData,
  setSelectedRowData,
  sectionNamesData,
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [searchClause, setSearchClause] = useState<boolean>(false);
  const [searchTags, setSearchTags] = useState<boolean>(false);
  const [searchContract, setSearchContract] = useState<boolean>(false);
  const [searchCreator, setSearchCreator] = useState<boolean>(false);
  const [openCurrentClauseModal, setOpenCurrentClauseModal] =
    useState<boolean>(false);
  const [fetchedClauseId, setFetchedClauseId] = useState<any>(null);

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumberChange(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const { data: tagsData } = useQuery({
    queryKey: ['tags-data'],
    queryFn: getContractsTags,
    select: (response: any) => response.results,
  });

  const { data: contractOption } = useQuery({
    queryKey: ['contract-list'],
    queryFn: fetchContractList,
  });

  const handleTagsClick = React.useCallback(
    (tag: any) => {
      handleFilterChange('tags', tag);
      setSearchTags(true);
    },
    [handleFilterChange, tagsData]
  );

  const handleContractClick = React.useCallback(
    (applicability: any) => {
      handleFilterChange('applicability', applicability);
      setSearchContract(true);
    },
    [handleFilterChange, contractOption]
  );

  const handleClauseNameClick = React.useCallback(
    async (rowData: any) => {
      setSelectedRowData(rowData);
      setOpenCurrentClauseModal(true);
      setFetchedClauseId(rowData?.id);
    },
    [setSelectedRowData]
  );

  const transformData = (data: any) => {
    const sectionMap = new Map<string, any>();

    data?.results?.forEach((result: any) => {
      const sectionName = Object.keys(result)[0];
      const sectionData = result[sectionName];

      sectionMap.set(sectionName, {
        id: sectionName,
        key: sectionName,
        clause_name: sectionName,
        section: sectionName,
        hasChildren: true,
        children: sectionData?.map((clause: any, clauseIndex: number) => ({
          id: clause?.id,
          key: `${sectionName}-${clauseIndex}`,
          clause_name: clause?.clause_name || '',
          applicability: clause?.applicability || [],
          tags: clause?.tags || [],
          creator: `${clause?.created_by?.first_name} ${clause?.created_by?.last_name}`,
          creator_first_name: clause?.created_by?.first_name,
          creator_last_name: clause?.created_by?.last_name,
          section_name: clause?.section_name,
          clause_type: clause?.clause_type || '',
          clause_text: clause?.clause_text || '',
        })),
      });
    });

    return Array.from(sectionMap.values()).flatMap((section) => {
      if (expandedRows.includes(section.id)) {
        return [section, ...section.children];
      } else {
        return [section];
      }
    });
  };

  const handleRowExpandToggle = (rowId: string) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(rowId)
        ? prevExpandedRows.filter((id) => id !== rowId)
        : [...prevExpandedRows, rowId]
    );
  };

  const { data: authorData } = useQuery({
    queryKey: ['author'],
    queryFn: getUsers,
    select: (response) => {
      const users = response?.users || [];
      return users.map((user: any) => ({
        ...user,
        name: `${user.first_name} ${user.last_name}`,
      }));
    },
  });

  const rows = transformData(data);

  const columns: GridColDef[] = [
    {
      field: 'clause_name',
      headerName: 'Clause',
      minWidth: 350,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return searchClause ? (
          <TableSearchInputComponent
            key="clause_name"
            setIsSearch={() => {
              setFilters((prev) => ({ ...prev, clause_name: null }));
              setSearchClause(false);
            }}
            placeholder="Search Name"
            handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
              handleFilterChange('clause_name', e.target.value)
            }
          />
        ) : (
          <TableHeaderWithSearch
            title="Clause Name"
            setIsSearch={setSearchClause}
          />
        );
      },
      renderCell: (params: any) => (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          onClick={() => handleRowExpandToggle(params?.row?.key)}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {params?.row?.hasChildren && (
              <IconButton aria-label="expand row" size="small">
                {expandedRows.includes(params?.row?.key) ? (
                  <ExpandMoreIcon
                    sx={{
                      background: '#996c84',
                      color: '#fff',
                      borderRadius: '50%',
                      fontSize: '1.2rem',
                    }}
                  />
                ) : (
                  <ChevronRightIcon
                    sx={{
                      background: '#996c84',
                      color: '#fff',
                      borderRadius: '50%',
                      fontSize: '1.2rem',
                    }}
                  />
                )}
              </IconButton>
            )}
            <Typography
              onClick={() => {
                if (!params?.row?.hasChildren) {
                  handleClauseNameClick(params?.row);
                }
              }}
              sx={{
                fontWeight: params?.row?.hasChildren ? '700' : 'normal',
                cursor: 'pointer',
                marginLeft: params?.row.hasChildren ? '' : '37px !important',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '15rem',
              }}
            >
              {params?.value}
            </Typography>
          </Stack>

          {!params.row.children || params.row.children.length === 0 ? (
            <Stack>
              <ClauseTableTooltip
                clause={params?.row}
                setSelectedRowData={setSelectedRowData}
                selectedRowData={selectedRowData}
                sectionNamesData={sectionNamesData}
              />
            </Stack>
          ) : null}
        </Stack>
      ),
    },
    {
      field: 'applicability',
      headerName: 'Applicability',
      minWidth: 250,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return searchContract ? (
          <TableSearchInputComponent
            key="applicability"
            setIsSearch={() => {
              setFilters((prev) => ({ ...prev, applicability: null }));
              setSearchContract(false);
            }}
            placeholder="Search Applicability"
            renderCustomInput={() => (
              <TableAsyncSelectInput
                key={
                  isArray(filters?.applicability)
                    ? (filters?.applicability?.[0] as string)
                    : 'applicability'
                }
                options={contractOption}
                valueKey="name"
                label="Select Applicability"
                value={filters?.applicability ? filters?.applicability : []}
                onChange={(value: string[]) =>
                  handleFilterChange('applicability', value)
                }
              />
            )}
          />
        ) : (
          <TableHeaderWithSearch
            title="Applicability"
            setIsSearch={setSearchContract}
          />
        );
      },
      renderCell: (params: any) =>
        params?.row?.children ? (
          ''
        ) : (
          <TableChipList
            id={`aApplicability-${params?.value?.id || 'default-id'}`}
            labelKey="name"
            valueKey="name"
            chipColor="#FFDDC4"
            list={params?.value || []}
            onClick={handleContractClick}
          />
        ),
    },
    {
      field: 'tags',
      headerName: 'Tags',
      minWidth: 250,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return searchTags ? (
          <TableSearchInputComponent
            key="tags"
            setIsSearch={() => {
              setFilters((prev) => ({ ...prev, tags: null }));
              setSearchTags(false);
            }}
            placeholder="Search Tags"
            renderCustomInput={() => (
              <TableAsyncSelectInput
                key={
                  isArray(filters?.tags)
                    ? (filters?.tags?.[0] as string)
                    : 'tags'
                }
                options={tagsData}
                valueKey="name"
                label="Select Tags"
                value={filters?.tags ? filters.tags : []}
                onChange={(value: string[]) =>
                  handleFilterChange('tags', value)
                }
              />
            )}
          />
        ) : (
          <TableHeaderWithSearch title="Tags" setIsSearch={setSearchTags} />
        );
      },
      renderCell: (params) =>
        params?.row?.children ? (
          ''
        ) : (
          <TableChipList
            id={`tags-${params?.value?.id || 'default-id'}`}
            labelKey="name"
            valueKey="name"
            chipColor="#C4DBFF"
            list={params?.value || []}
            onClick={handleTagsClick}
          />
        ),
    },
    {
      field: 'creator',
      headerName: 'Creator',
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return searchCreator ? (
          <TableSearchInputComponent
            key="creator"
            setIsSearch={() => {
              setFilters((prev) => ({ ...prev, creator: null }));
              setSearchCreator(false);
            }}
            placeholder="Search Creators"
            renderCustomInput={() => (
              <TableAsyncSelectInput
                key={
                  isArray(filters?.creator)
                    ? (filters?.creator?.[0] as string)
                    : 'creator'
                }
                options={authorData}
                valueKey="id"
                label="Select Creators"
                value={filters?.creator ? filters.creator : []}
                onChange={(value: string[]) =>
                  handleFilterChange('creator', value)
                }
              />
            )}
          />
        ) : (
          <TableHeaderWithSearch
            title="Creator"
            setIsSearch={setSearchCreator}
          />
        );
      },
      renderCell: (params: any) =>
        !params?.row?.children || params?.row?.children?.length === 0 ? (
          <NameAvatar
            firstName={params?.row?.creator_first_name}
            lastName={params?.row?.creator_last_name}
          />
        ) : null,
    },
  ];

  return (
    <React.Fragment>
      <DataGridTable
        rows={rows}
        columns={columns}
        rowCount={data?.count}
        isLoading={isLoading}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        setSorting={setSorting}
        setSelectedRow={setSelectedRow}
      />

      <CreateNewClauseModal
        open={openCurrentClauseModal}
        onClose={() => setOpenCurrentClauseModal(false)}
        clauseId={fetchedClauseId}
        sectionNamesData={sectionNamesData}
      />
    </React.Fragment>
  );
};

export default ClauseTable;
