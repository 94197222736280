import React, { useCallback, useEffect, useState } from 'react';

import { LinearProgress, Stack } from '@mui/material';

import TemplateList from './TemplateList';
import DrawerComponent from '../../../RiverusUI/Components/DrawerComponent';
import SearchInputComponent from '../../../RiverusUI/Components/SearchInput';
import TabComponent from '../../../RiverusUI/Components/TabComponent';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  templateList: string[];
  loadingTemplateList: boolean;
  control: any;
  name: string;
  executedContractsList?: string[];
  earlierDraftsList?: string[];
  type?: string;
  labelKey: string;
}

export const TemplateDrawer: React.FC<Props> = ({
  open,
  onClose,
  templateList,
  loadingTemplateList,
  control,
  name,
  executedContractsList,
  earlierDraftsList,
  type = 'template',
  labelKey,
}) => {
  const [searchedList, setSearchedList] = useState<any>([]);
  const [selectedTabValue, setSelectedTabValue] =
    React.useState('earlier_drafts');

  const handleSearch = useCallback(
    (searchQuery: any) => {
      if (type === 'template') {
        const searchFilter = templateList?.filter((item: any) =>
          item?.file_name?.toLowerCase().includes(searchQuery?.toLowerCase())
        );
        if (searchQuery.trim() !== '') {
          setSearchedList(searchFilter);
        } else {
          setSearchedList(templateList);
        }
      } else if (type === 'draft') {
        if (selectedTabValue === 'earlier_drafts') {
          const searchFilterEarlierDraft = earlierDraftsList?.filter(
            (item: any) =>
              item?.file_name
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase())
          );
          if (searchQuery.trim() !== '') {
            setSearchedList(searchFilterEarlierDraft);
          } else {
            setSearchedList(earlierDraftsList);
          }
        }
        if (selectedTabValue === 'executed_contracts') {
          const searchFilterExecutedDraft = executedContractsList?.filter(
            (item: any) =>
              item?.file_name
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase())
          );
          if (searchQuery.trim() !== '') {
            setSearchedList(searchFilterExecutedDraft);
          } else {
            setSearchedList(executedContractsList);
          }
        }
      }
    },
    [
      type,
      templateList,
      selectedTabValue,
      earlierDraftsList,
      executedContractsList,
    ]
  );

  useEffect(() => {
    if (type === 'draft') {
      if (selectedTabValue === 'earlier_drafts') {
        setSearchedList(earlierDraftsList);
      }
      if (selectedTabValue === 'executed_contracts') {
        setSearchedList(executedContractsList);
      }
    } else {
      setSearchedList(templateList);
    }
  }, [
    templateList,
    selectedTabValue,
    executedContractsList,
    earlierDraftsList,
    type,
  ]);

  const tabPropsArray = [
    {
      value: 'earlier_drafts',
      label: 'Earlier drafts',
      children: (
        <TemplateList
          templateList={searchedList || []}
          control={control}
          name={name}
          labelKey="contractName"
          type="earlierDraft"
        />
      ),
    },
    {
      value: 'executed_contracts',
      label: 'Executed contracts',
      children: (
        <TemplateList
          templateList={searchedList || []}
          control={control}
          name={name}
          labelKey={labelKey}
          type="executedDraft"
        />
      ),
    },
  ];

  return (
    <DrawerComponent
      open={open}
      onClose={onClose}
      drawerHeader={`Search and select ${
        type === 'draft' ? ' an executed / earlier' : ' a templates'
      } `}
    >
      <Stack direction="column" spacing={2} margin="20px 15px" height="100%">
        <SearchInputComponent
          placeholder="Filter by name or tags"
          handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
            handleSearch(e?.target?.value)
          }
        />
        {loadingTemplateList ? (
          <LinearProgress />
        ) : (
          <>
            {type === 'draft' ? (
              <TabComponent
                tabs={tabPropsArray}
                tabValue={selectedTabValue}
                setTabValue={setSelectedTabValue}
              />
            ) : (
              <TemplateList
                templateList={searchedList}
                control={control}
                name={name}
                labelKey={labelKey}
                type="template"
              />
            )}
          </>
        )}
      </Stack>
    </DrawerComponent>
  );
};
