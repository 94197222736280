import React, { useMemo, useState } from 'react';

import CallMadeIcon from '@mui/icons-material/CallMade';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import TabIcon from '@mui/icons-material/Tab';
import {
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import * as changesets from 'json-diff-ts';

import ConnectedDocsDrawer from './ConnectedDocsDrawer';
import { fetchContractById } from '../../../Services/DocumentLibrary';

interface Props {
  contractData: any;
}

const TableTooltip: React.FC<Props> = ({ contractData }) => {
  const [contractDetails, setContractDetails] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setContractDetails({});
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const { data: contract_data, isLoading: contractLoading } = useQuery({
    queryKey: ['get_contract_data_id', contractDetails?.id],
    queryFn: async () => {
      const response = await fetchContractById(contractDetails?.id);
      return response;
    },
    enabled: !!contractDetails?.id,
  });

  const { data: refContractData } = useQuery({
    queryKey: ['get_ref_contracts', contractDetails?.id],
    queryFn: async () => {
      const response = await fetchContractById(contract_data?.refcontract_id);
      return response;
    },
    enabled: !!contract_data?.refcontract_id,
  });

  const contracting_parties = useMemo(() => {
    let changeList;
    if (contractData?.contracting_parties?.length > 0) {
      const diff = JSON.parse(
        JSON.stringify(contractData?.contracting_parties?.[0]?.edited_content)
      );

      const orig = JSON.parse(
        JSON.stringify(contractData?.contracting_parties?.[0]?.raw_content)
      );

      if (diff) {
        const unFlattenChanges = changesets.unflattenChanges(diff.update);
        changeList = JSON.parse(
          JSON.stringify(
            changesets.applyChangeset(
              JSON.parse(JSON.stringify(orig)),
              unFlattenChanges
            )
          )
        );
      } else {
        changeList = JSON.parse(
          JSON.stringify(
            changesets.applyChangeset(JSON.parse(JSON.stringify(orig)), diff)
          )
        );
      }
      return changeList.contracting_parties;
    }
    return changeList;
  }, [contractData?.contracting_parties]);

  return (
    <>
      <IconButton
        onClick={(event) => {
          handleClick(event);
          setContractDetails(contractData);
        }}
        aria-describedby="table_popover"
      >
        <CallMadeIcon style={{ color: '#6D264C' }} />
      </IconButton>

      <Popover
        id="table_popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack direction="column" spacing={1} paddingTop="20px">
          <Stack direction="column" spacing={1} padding="0 20px">
            <Stack direction="row" spacing={1}>
              <Typography fontSize="15px" fontWeight={600}>
                Title:
              </Typography>
              <Typography fontSize="15px">{contractDetails?.title}</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography fontSize="15px" fontWeight={600}>
                Nature:
              </Typography>
              {contractDetails?.contract_type?.map(
                (item: any, index: number) => (
                  <Typography key={index} fontSize="15px">
                    {item?.name}
                  </Typography>
                )
              )}
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography fontSize="15px" fontWeight={600}>
                Expiry:
              </Typography>
              <Typography fontSize="15px">
                {contractDetails?.end_date || 'N/A'}
              </Typography>
            </Stack>

            {contracting_parties?.length > 0 && (
              <Stack direction="column" spacing={1} pt="15px">
                <Typography fontSize="15px" fontWeight={600}>
                  Contracting Parties
                </Typography>
                {contracting_parties?.map((item: any, index: number) => (
                  <Typography key={index} fontSize="15px">
                    {index + 1}). {item?.party ? item?.party : item?.partyName}
                  </Typography>
                ))}
              </Stack>
            )}

            {(contract_data?.support_document?.length > 0 ||
              contract_data?.amendment?.length > 0 ||
              contract_data?.uploaded_type === 'Amendment') && (
              <Stack direction="column" spacing={1} pt="15px">
                <Typography fontSize="15px" fontWeight={600}>
                  Connected Documents
                </Typography>
                {contract_data?.uploaded_type === 'Amendment' &&
                  refContractData && (
                    <Stack spacing={2} direction="row">
                      <Typography fontSize="15px">
                        1). {refContractData?.file_name}
                      </Typography>
                      <Chip label="Master" />
                    </Stack>
                  )}
                {contract_data?.amendment?.length > 0 && (
                  <Stack direction="column" spacing={1}>
                    <Typography fontSize="14px" fontWeight={600}>
                      Amendment Documents
                    </Typography>

                    {contract_data?.amendment?.map(
                      (item: any, index: number) => (
                        <Typography key={index} fontSize="15px">
                          {index + 1}). {item?.file_name}
                        </Typography>
                      )
                    )}
                  </Stack>
                )}
                {contract_data?.support_document?.length > 0 && (
                  <Stack direction="column" spacing={1}>
                    <Typography fontSize="14px" fontWeight={600}>
                      Support Documents
                    </Typography>

                    {contract_data?.support_document?.map(
                      (item: any, index: number) => (
                        <Typography key={index} fontSize="15px">
                          {index + 1}). {item?.file_name}
                        </Typography>
                      )
                    )}
                  </Stack>
                )}
              </Stack>
            )}

            <Stack direction="column" spacing={1} pt="15px">
              <Typography fontSize="15px" fontWeight={600}>
                Uploaded by
              </Typography>
              <Typography fontSize="15px">
                {contract_data?.created_by}
              </Typography>
              <Typography fontSize="15px">
                on{' '}
                {dayjs(contractDetails?.created_on).format(
                  'MMMM D, YYYY h:mm A'
                )}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Button
              startIcon={<LaunchOutlinedIcon />}
              style={{ fontSize: '15px' }}
              onClick={() => {
                window.open(
                  '/document/documentlibrary/' +
                    btoa(contractDetails?.id?.toString()),
                  '_blank'
                );
              }}
            >
              Open Contract
            </Button>
            <Button
              startIcon={<TabIcon />}
              style={{ fontSize: '15px' }}
              onClick={() => setOpenDrawer(true)}
            >
              Show connected documents
            </Button>
          </Stack>
        </Stack>
      </Popover>
      {openDrawer && (
        <ConnectedDocsDrawer
          open={openDrawer}
          onClose={handleCloseDrawer}
          contract_data={contract_data}
          contractLoading={contractLoading}
          refContractData={refContractData}
        />
      )}
    </>
  );
};

export default TableTooltip;
