import React, { useEffect, useMemo, useState } from 'react';

import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Button, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';

import { TemplateDrawer } from './TemplateDrawer';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  fetchEarlierDrafts,
  fetchExecutedContracts,
} from '../../../Services/Draft';

interface Props {
  selectedContractId: string;
  selectedContractName: string;
  selectedDisplayName: string;
  name: string;
  control?: any;
  options?: any;
  loading?: boolean;
  type?: string;
  valueKey?: string;
  setSelectedLinkedDraft?: any;
}

const LinkSelectedTemplateField: React.FC<Props> = ({
  selectedContractId,
  selectedContractName,
  name,
  control,
  options,
  loading = false,
  type = 'template',
  selectedDisplayName,
  valueKey,
  setSelectedLinkedDraft,
}) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const { watch } = useFormContext();
  const linked_draft = watch('linked_draft') || '';

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  // to get Executed Contracts according to selected contract type.
  const { data: getExecutedContracts, isFetching: loadingExecutedContracts } =
    useQuery({
      queryKey: ['executed_contracts', selectedContractId],
      queryFn: async () => {
        const response = await fetchExecutedContracts(selectedContractId);
        return response?.results;
      },
      enabled: !!(selectedContractId && type === 'draft'),
    });

  // to get Earlier Drafts according to selected contract type
  const { data: getEarlierDrafts, isFetching: loadingEarlierDrafts } = useQuery(
    {
      queryKey: ['earlier_draft', selectedContractId],
      queryFn: async () => {
        const response = await fetchEarlierDrafts(selectedDisplayName);
        return response?.results;
      },
      enabled: !!(selectedContractId && type === 'draft'),
    }
  );

  const draftList = useMemo(() => {
    const executedContracts =
      getExecutedContracts?.map((data: any) => ({
        ...data,
        type: 'executed',
      })) || [];
    const earlierDrafts =
      getEarlierDrafts?.map(
        (data: any) =>
          (data = { ...data, title: data.contractName, type: 'earlier' })
      ) || [];
    return [...executedContracts, ...earlierDrafts];
  }, [getEarlierDrafts, getExecutedContracts]);

  const documentCount = useMemo(() => {
    if (type === 'template') {
      return options?.length || 0;
    } else {
      return draftList?.length || 0;
    }
  }, [draftList, options, type]);

  useEffect(() => {
    const draft = draftList.filter((data: any) => data.id === linked_draft);
    setSelectedLinkedDraft?.(draft[0]);
  }, [draftList, linked_draft, setSelectedLinkedDraft]);

  const label_key = useMemo(() => {
    if (type === 'template') {
      return 'file_name';
    } else {
      return 'title';
    }
  }, [type]);

  const helperText: string = useMemo(() => {
    if (selectedContractName) {
      return `${documentCount} ${type} found under contract type “${selectedContractName}”`;
    } else {
      return 'Please select any contract type';
    }
  }, [selectedContractName, documentCount]);

  return (
    <>
      <Grid container alignItems="start" mt={2}>
        <Grid item sm={9}>
          <RISelectComponent
            isShowDropDown={type === 'draft'}
            label={`Select ${type}`}
            required={type !== 'draft'}
            name={name}
            control={control}
            options={type === 'template' ? options || [] : draftList || []}
            labelKey={label_key}
            valueKey={valueKey}
            textFieldProps={{
              helperText: helperText,
            }}
            loading={
              loading || loadingExecutedContracts || loadingEarlierDrafts
            }
            renderOption={type === 'draft' ? () => null : undefined}
          />
        </Grid>
        <Grid item sm={3}>
          <Button
            variant="text"
            startIcon={<ManageSearchIcon />}
            onClick={() => {
              setOpenDrawer(true);
            }}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Find {type}
          </Button>
        </Grid>
      </Grid>
      <TemplateDrawer
        open={openDrawer}
        onClose={handleCloseDrawer}
        templateList={options}
        loadingTemplateList={
          loading || loadingEarlierDrafts || loadingExecutedContracts
        }
        control={control}
        name={name}
        executedContractsList={getExecutedContracts || []}
        earlierDraftsList={getEarlierDrafts || []}
        type={type}
        labelKey={label_key}
      />
    </>
  );
};

export default LinkSelectedTemplateField;
