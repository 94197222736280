import React, { FC, useCallback, useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, LinearProgress, Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import StampTable from './StampTable';
import ControlledTextField from '../RiverusUI/Components/ControlledTextField';
import CustomModal from '../RiverusUI/Components/CustomModal';
import RISelectComponent from '../RiverusUI/Components/SelectComponent';
import { fetchStamps, fetchState, postStamp } from '../Services/Stamp';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
}

const CreateStampForm: FC<IProps> = (props) => {
  const { open, onClose } = props;
  const methods = useForm();
  const { handleSubmit, control, watch, resetField } = methods;
  const state = watch('state') || '';

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: stateData, isLoading: stateLoading } = useQuery({
    queryKey: ['StateList'],
    queryFn: fetchState,
  });

  const { mutate: addStampMutation } = useMutation({
    mutationKey: ['add-stamp'],
    mutationFn: postStamp,
    onSuccess: () => {
      enqueueSnackbar('Stamp paper added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({ queryKey: ['stamp-bank-list'] });
      queryClient.invalidateQueries({ queryKey: ['stamp-bank'] });
      resetField('serial_number');
      resetField('denomination');
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.non_field_errors?.[0];
      enqueueSnackbar(`${responseData || 'Failed to create Stamp paper!'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const stateName = useMemo(() => {
    if ((stateData as any)?.results) {
      return (stateData as any).results.filter(
        (data: any) => data.id === state
      )?.[0]?.name;
    } else {
      return '';
    }
  }, [stateData, state]);

  const { data: stampBank, isLoading: stampLoading } = useQuery({
    queryKey: ['stamp-bank-list', stateName],
    queryFn: () => fetchStamps(`?state=${stateName}`),
    enabled: !!stateName,
  });

  const onSubmit = useCallback(
    (data: any) => {
      addStampMutation(data);
    },
    [addStampMutation]
  );

  return (
    <CustomModal
      maxWidth="md"
      title="Add/Modify stamp-papers"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ minWidth: '600px', padding: '30px 10px' }} gap={2}>
            <RISelectComponent
              required
              name="state"
              control={control}
              label="Select State(province)"
              options={(stateData as any)?.results}
              loading={stateLoading}
            />
            {state && (
              <Stack gap={1}>
                {stampLoading ? (
                  <LinearProgress />
                ) : (
                  <StampTable data={stampBank} isLoading={false} />
                )}
                <Stack direction="row" gap={1}>
                  <ControlledTextField
                    name="serial_number"
                    control={control}
                    placeholder="Serial Number"
                  />
                  <ControlledTextField
                    name="denomination"
                    control={control}
                    placeholder="value"
                    type="number"
                  />
                  <Button
                    variant="contained"
                    sx={{ whiteSpace: 'nowrap' }}
                    startIcon={<AddIcon />}
                    type="submit"
                  >
                    Add Record
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateStampForm;
