import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Stack, Tab } from '@mui/material';

interface TabProps {
  value: string;
  label: string;
  children: ReactNode;
}

interface Props {
  tabs: TabProps[];
  tabValue: string;
  setTabValue: Dispatch<SetStateAction<string>>;
}

const TabComponent: React.FC<Props> = ({
  tabs,
  tabValue,
  setTabValue,
  ...Props
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <TabContext value={tabValue}>
      <Stack width="100%" height="100%" {...Props}>
        <TabList onChange={handleChange}>
          {tabs?.map((items, index) => (
            <Tab key={index} value={items?.value} label={items?.label} />
          ))}
        </TabList>
        {tabs?.map((items, index) => (
          <TabPanel
            key={index}
            value={items?.value}
            sx={{ padding: '8px', height: '100%' }}
          >
            {items?.children}
          </TabPanel>
        ))}
      </Stack>
    </TabContext>
  );
};

export default TabComponent;
