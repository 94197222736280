/* eslint-disable @typescript-eslint/no-unused-vars */
import { connect } from 'react-redux';

import AggregatesActionGenerator from '../../Aggregates/Actions/AggregatesGen';
import { AppliedFilters } from '../../Aggregates/State/AggregatesState';
import AppActionGenerator from '../../App/Actions/actionGen';
import SearchBarActionGenerator from '../../UniversalComponents/SearchBar/Action/actionGen';
import { StoreTree } from '../../Utils/MainReducer/mainReducer';
import SearchActionGen from '../Actions/SearchGen';
import Search from '../Components/search';

export function mapStateToProps(appState: StoreTree, ownProps: any) {
  return {
    searchResult: appState.search.searchResult,
    searchLoader: appState.search.searchLoader,
    term: appState.searchBar.term,
    filterType: appState.searchBar.filterType,
    isFilterResultEmpty: appState.aggregates.isFilterResultEmpty,
  };
}
export function mapDispatchToProps(dispatch: any, ownProps: any) {
  return {
    pageWatcher: (pageType: string) =>
      dispatch(AppActionGenerator.pageWatcher(pageType)),
    InitFilter: (appliedFilters: AppliedFilters[], sort: string) =>
      dispatch(AggregatesActionGenerator.InitFilter(appliedFilters, sort)),
    search: (
      searchTerm: string,
      sortBy: string,
      pageSize: number,
      filterType: string
    ) =>
      dispatch(
        SearchActionGen.search(searchTerm, sortBy, pageSize, filterType)
      ),
    searchMore: (
      searchTerm: string,
      sortBy: string,
      pageSize: number,
      filterType: string
    ) =>
      dispatch(
        SearchActionGen.search(searchTerm, sortBy, pageSize, filterType)
      ),
    textChange: (term: string, filterType: string) =>
      dispatch(SearchBarActionGenerator.changeText(term, filterType)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Search);
