import React, { useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Box,
  Card,
  Chip,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { ClauseNameMap } from '../../DocumentView/Component/ClauseComponent/Components/ClauseComponent';
import { CLAUSE_RENDERING_ORDER } from '../../Draft/Component/InsightsTab/constants';
import CheckboxButtonGroup from '../../RiverusUI/Components/CheckboxButtonGroup';
import ControlledTextField from '../../RiverusUI/Components/ControlledTextField';
import CheckBoxFilled from '../../RiverusUI/Components/Icons/CheckboxFilled';
import FilterBg from '../../RiverusUI/Components/Icons/FilterBg';
import ListSkeleton from '../../RiverusUI/Components/Skeleton/ListSkeleton';
import { propertyExists } from '../../RiverusUI/Components/utils';
import { getSearchResult } from '../../Services/search';

// 818181
const scrollContainerSx = {
  maxHeight: '420px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#e3e3e3',
    borderRadius: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#996c84',
    borderRadius: '6px',
  },
};

const filterHeaderStyle = {
  fontWeight: 'bold',
  backgroundColor: '#88305F14',
  padding: '10px',
  borderBottom: '1px solid gray',
};

const Search = () => {
  const { query } = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const { control, watch, setValue } = methods;
  const [searchData, setSearchData] = useState<any>();
  const [ContractingPartiesData, setContractingPartiesData] = useState<any>();
  const [searchContractingParties, setSearchContractingParties] =
    useState<boolean>(false);
  // const [searchTerm, setSearchTerm] = useState<{ search: string[] }>({
  //   search: [],
  // });

  const clause_type = watch('clause_type');
  const jurisdiction = watch('jurisdiction') || null;
  const contracting_parties = watch('contracting_parties') || null;
  const contract_type = watch('contract_type') || null;
  const groups = watch('groups') || null;
  const projects = watch('projects') || null;
  const search_contracting_parties =
    watch('search_contracting_parties') || null;
  const [searchContractingPartiesValue] = useDebounce(
    search_contracting_parties,
    1000
  );

  const searchTerm = useMemo(
    () => (query ? JSON.parse(atob(query)) : { search: [] }),
    [query]
  );

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'search-result',
      searchTerm,
      clause_type,
      jurisdiction,
      contracting_parties,
      contract_type,
      groups,
      projects,
    ],
    queryFn: () => {
      let ids: any = [];
      clause_type?.map((data: any) => {
        ids = Array.from(new Set([...ids, ...data.ids]));
      });

      const clauseTypes =
        clause_type?.map((data: any) => data.clause_name) || [];

      return getSearchResult({
        ...searchTerm,
        ...(clauseTypes.length > 0 && { ids: ids }),
        ...(groups?.length > 0 && { groups: groups }),
        ...(projects?.length > 0 && { projects: projects }),
        ...(contract_type?.length > 0 && { contract_type: contract_type }),
        ...(jurisdiction?.length > 0 && { jurisdiction: jurisdiction }),
        ...(contracting_parties?.length > 0 && {
          contracting_parties: contracting_parties,
        }),
      });
    },
  });

  useEffect(() => {
    if (data) {
      setSearchData(data);
    }
  }, [data]);

  const searchResultIds = useMemo(() => {
    const searchIds: any = [];
    if (searchData?.length) {
      searchData.map((data: any) =>
        data?.ids?.map((idsData: any) => {
          const index = searchIds.findIndex(
            (searchItem: any) => searchItem.id === idsData.id
          );
          if (index === -1) {
            searchIds.push(idsData);
          }
        })
      );
    }
    return searchIds;
  }, [searchData]);

  const clauseTypeData = useMemo(() => {
    const clauses: any = searchData ? [] : null;
    CLAUSE_RENDERING_ORDER.map((clause: string) =>
      searchData?.map((data: any) => {
        if (clause === data?.clause_name) {
          const updatedData = {
            ...data,
            label: ClauseNameMap[data.clause_name],
            ids: data.ids.map((item: any) => item.id),
          };
          clauses.push(updatedData);
        }
      })
    );
    return clauses;
  }, [searchData]);

  const JurisdictionData = useMemo(() => {
    const jurisdiction: any = searchData ? [] : null;
    searchData?.map((data: any) => {
      if (propertyExists(data, 'jurisdiction_counts')) {
        const jurisdictionCounts = data.jurisdiction_counts;
        for (const key in jurisdictionCounts) {
          const newObj = {
            label: key,
            count: jurisdictionCounts[key],
          };
          jurisdiction.push(newObj);
        }
      }
    });
    const sortedData = jurisdiction?.sort(function (a: any, b: any) {
      return b.count - a.count;
    });
    return sortedData;
  }, [searchData]);

  useEffect(() => {
    const contractingParties: any = searchData ? [] : null;
    searchData?.map((data: any) => {
      if (propertyExists(data, 'contracting_parties')) {
        const contracting_parties = data.contracting_parties;
        for (const key in contracting_parties) {
          const newObj = {
            label: key,
            count: contracting_parties[key],
          };
          contractingParties.push(newObj);
        }
      }
    });
    const sortedData = contractingParties?.sort(function (a: any, b: any) {
      return b.count - a.count;
    });
    if (searchContractingPartiesValue) {
      const parties = [...sortedData];
      const filteredData = parties.filter((data: any) =>
        data.label.includes(searchContractingPartiesValue)
      );
      setContractingPartiesData(filteredData);
    } else {
      setContractingPartiesData(sortedData);
    }
  }, [searchData, searchContractingPartiesValue]);

  const groupsData = useMemo(() => {
    const groupsTempData: any = searchData ? [] : null;
    searchData?.map((data: any) => {
      if (propertyExists(data, 'groups')) {
        const groups = data.groups;
        for (const key in groups) {
          const newObj = {
            label: key,
            count: groups[key],
          };
          groupsTempData.push(newObj);
        }
      }
    });
    const sortedData = groupsTempData?.sort(function (a: any, b: any) {
      return b.count - a.count;
    });
    return sortedData;
  }, [searchData]);

  const contractType = useMemo(() => {
    const contractType: any = searchData ? [] : null;
    searchData?.map((data: any) => {
      if (propertyExists(data, 'contract_types')) {
        const contract_type = data.contract_types;
        for (const key in contract_type) {
          const newObj = {
            label: key,
            count: contract_type[key],
          };
          contractType.push(newObj);
        }
      }
    });
    const sortedData = contractType?.sort(function (a: any, b: any) {
      return b.count - a.count;
    });
    return sortedData;
  }, [searchData]);

  const projectsData = useMemo(() => {
    const projectTempData: any = searchData ? [] : null;
    searchData?.map((data: any) => {
      if (propertyExists(data, 'projects')) {
        const projects = data.projects;
        for (const key in projects) {
          const newObj = {
            label: key,
            count: projects[key],
          };
          projectTempData.push(newObj);
        }
      }
    });
    const sortedData = projectTempData?.sort(function (a: any, b: any) {
      return b.count - a.count;
    });
    return sortedData;
  }, [searchData]);

  const handleSearchTermDelete = (term: string) => {
    let searchQuery = searchTerm;
    const filteredData = searchQuery.search.filter(
      (item: any) => item !== term
    );
    searchQuery = {
      search: filteredData,
    };
    const url = `/search/${btoa(JSON.stringify(searchQuery))}`;
    navigate(url);
  };

  const handleContractTypeDelete = (item: string) => {
    const filteredData = contract_type.filter((data: string) => data !== item);
    setValue('contract_type', filteredData);
  };

  const handleClauseTypeDelete = (item: string) => {
    const filteredData = clause_type.filter(
      (data: any) => data.clause_name !== item
    );
    setValue('clause_type', filteredData);
  };

  const handleJurisdictionDelete = (item: string) => {
    const filteredData = jurisdiction.filter((data: string) => data !== item);
    setValue('jurisdiction', filteredData);
  };

  const handleContractingPartiesDelete = (item: string) => {
    const filteredData = contracting_parties.filter(
      (data: string) => data !== item
    );
    setValue('contracting_parties', filteredData);
  };

  const handleGroupsDelete = (item: string) => {
    const filteredData = groups.filter((data: string) => data !== item);
    setValue('groups', filteredData);
  };
  const handleProjectsDelete = (item: string) => {
    const filteredData = projects.filter((data: string) => data !== item);
    setValue('projects', filteredData);
  };

  const showSearchResult = useMemo(
    () =>
      searchTerm.search.length ||
      contract_type?.length ||
      groups?.length ||
      projects?.length ||
      jurisdiction?.length ||
      clause_type?.length ||
      contracting_parties?.length,
    [
      searchTerm,
      contract_type,
      jurisdiction,
      groups,
      projects,
      clause_type,
      contracting_parties,
    ]
  );

  if (isLoading && !searchData) {
    return <ListSkeleton numberOfLines={10} />;
  }
  return (
    <Stack direction="row" gap={2}>
      <Stack
        minWidth="28vw"
        sx={{ ...scrollContainerSx, maxHeight: '83vh', paddingRight: '6px' }}
      >
        <FormProvider {...methods}>
          <form>
            <Stack gap={2}>
              {contractType?.length > 0 && (
                <Card>
                  <Typography sx={filterHeaderStyle}>Contract Type</Typography>
                  <Stack gap={1} sx={scrollContainerSx}>
                    <CheckboxButtonGroup
                      name="contract_type"
                      control={control}
                      options={contractType}
                      labelKey="label"
                      valueKey="label"
                      checkBoxProps={{
                        checkedIcon: (
                          <CheckBoxFilled
                            style={{ fill: 'none !important' }}
                            color="#88305F"
                          />
                        ),
                      }}
                      renderCustomComponent={(value) => (
                        <LinearProgress
                          variant="determinate"
                          sx={{
                            background: 'transparent',
                            borderBottom: '1px solid #D1D3D4',
                          }}
                          value={(value / searchResultIds?.length) * 100}
                        />
                      )}
                      labelProps={{
                        sx: {
                          padding: '2px 2px 2px 10px',
                        },
                      }}
                    />
                  </Stack>
                </Card>
              )}
              <Card>
                <Typography sx={filterHeaderStyle}>Clause Type</Typography>
                {clauseTypeData?.length ? (
                  <Stack gap={1} sx={scrollContainerSx}>
                    <CheckboxButtonGroup
                      name="clause_type"
                      control={control}
                      options={clauseTypeData}
                      valueKey="clause_name"
                      checkBoxProps={{
                        checkedIcon: (
                          <CheckBoxFilled
                            style={{ fill: 'none !important' }}
                            color="#88305F"
                          />
                        ),
                      }}
                      returnObject
                      renderCustomComponent={(value) => (
                        <LinearProgress
                          variant="determinate"
                          sx={{
                            background: 'transparent',
                            borderBottom: '1px solid #D1D3D4',
                          }}
                          value={(value / searchResultIds?.length) * 100}
                        />
                      )}
                      labelProps={{
                        sx: {
                          padding: '2px 2px 2px 10px',
                        },
                      }}
                    />
                  </Stack>
                ) : (
                  <ListSkeleton numberOfLines={6} />
                )}
              </Card>
              {groupsData?.length > 0 && (
                <Card>
                  <Typography sx={filterHeaderStyle}>Groups</Typography>
                  <Stack gap={1} sx={scrollContainerSx}>
                    <CheckboxButtonGroup
                      name="groups"
                      control={control}
                      options={groupsData}
                      labelKey="label"
                      valueKey="label"
                      checkBoxProps={{
                        checkedIcon: (
                          <CheckBoxFilled
                            style={{ fill: 'none !important' }}
                            color="#88305F"
                          />
                        ),
                      }}
                      renderCustomComponent={(value) => (
                        <LinearProgress
                          variant="determinate"
                          sx={{
                            background: 'transparent',
                            borderBottom: '1px solid #D1D3D4',
                          }}
                          value={(value / searchResultIds?.length) * 100}
                        />
                      )}
                      labelProps={{
                        sx: {
                          padding: '2px 2px 2px 10px',
                        },
                      }}
                    />
                  </Stack>
                </Card>
              )}
              {projectsData?.length > 0 && (
                <Card>
                  <Typography sx={filterHeaderStyle}>Projects</Typography>
                  <Stack gap={1} sx={scrollContainerSx}>
                    <CheckboxButtonGroup
                      name="projects"
                      control={control}
                      options={projectsData}
                      labelKey="label"
                      valueKey="label"
                      checkBoxProps={{
                        checkedIcon: (
                          <CheckBoxFilled
                            style={{ fill: 'none !important' }}
                            color="#88305F"
                          />
                        ),
                      }}
                      renderCustomComponent={(value) => (
                        <LinearProgress
                          variant="determinate"
                          sx={{
                            background: 'transparent',
                            borderBottom: '1px solid #D1D3D4',
                          }}
                          value={(value / searchResultIds?.length) * 100}
                        />
                      )}
                      labelProps={{
                        sx: {
                          padding: '2px 2px 2px 10px',
                        },
                      }}
                    />
                  </Stack>
                </Card>
              )}
              {JurisdictionData?.length > 0 && (
                <Card>
                  <Typography sx={filterHeaderStyle}>Jurisdiction</Typography>
                  <Stack gap={1} sx={scrollContainerSx}>
                    <CheckboxButtonGroup
                      name="jurisdiction"
                      control={control}
                      options={JurisdictionData}
                      labelKey="label"
                      valueKey="label"
                      checkBoxProps={{
                        checkedIcon: (
                          <CheckBoxFilled
                            style={{ fill: 'none !important' }}
                            color="#88305F"
                          />
                        ),
                      }}
                      renderCustomComponent={(value) => (
                        <LinearProgress
                          variant="determinate"
                          value={(value / searchResultIds?.length) * 100}
                          sx={{
                            background: 'transparent',
                            borderBottom: '1px solid #D1D3D4',
                          }}
                        />
                      )}
                      labelProps={{
                        sx: {
                          padding: '2px 2px 2px 10px',
                        },
                      }}
                    />
                  </Stack>
                </Card>
              )}
              {ContractingPartiesData?.length > 0 && (
                <Card>
                  <Box sx={filterHeaderStyle}>
                    {searchContractingParties ? (
                      <ControlledTextField
                        label="Search contracting parties"
                        name="search_contracting_parties"
                        fullWidth
                        control={control}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                sx={{ color: '#88305F' }}
                                onClick={() =>
                                  setSearchContractingParties(false)
                                }
                                edge="end"
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography fontWeight={600}>
                          Contracting Parties
                        </Typography>
                        <IconButton
                          onClick={() => setSearchContractingParties(true)}
                        >
                          <SearchOutlinedIcon />
                        </IconButton>
                      </Stack>
                    )}
                  </Box>
                  <Stack gap={1} sx={scrollContainerSx}>
                    <CheckboxButtonGroup
                      name="contracting_parties"
                      control={control}
                      options={ContractingPartiesData}
                      labelKey="label"
                      valueKey="label"
                      checkBoxProps={{
                        checkedIcon: (
                          <CheckBoxFilled
                            style={{ fill: 'none !important' }}
                            color="#88305F"
                          />
                        ),
                      }}
                      renderCustomComponent={(value) => (
                        <LinearProgress
                          variant="determinate"
                          value={(value / searchResultIds?.length) * 100}
                          sx={{
                            background: 'transparent',
                            borderBottom: '1px solid #D1D3D4',
                          }}
                        />
                      )}
                      labelProps={{
                        sx: {
                          padding: '2px 2px 2px 10px',
                        },
                      }}
                    />
                  </Stack>
                </Card>
              )}
            </Stack>
          </form>
        </FormProvider>
      </Stack>

      {showSearchResult ? (
        <Stack gap={2} flex={1}>
          <Typography>
            <strong>{searchResultIds?.length}</strong> results for
          </Typography>
          {isFetching && <LinearProgress variant="indeterminate" />}
          <Stack direction="row" gap={1} flexWrap="wrap">
            {searchTerm.search.map((term: string) => (
              <Chip
                key={term}
                label={term}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                deleteIcon={<CloseIcon fontSize="small" />}
                onDelete={() => handleSearchTermDelete(term)}
                icon={<SearchOutlinedIcon fontSize="small" />}
              />
            ))}
            {contract_type?.map((item: any, index: number) => (
              <Chip
                key={`contract-type-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleContractTypeDelete(item)}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {groups?.map((item: any, index: number) => (
              <Chip
                key={`groups-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleGroupsDelete(item)}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {projects?.map((item: any, index: number) => (
              <Chip
                key={`projects-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleProjectsDelete(item)}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {clause_type?.map((clause: any, index: number) => (
              <Chip
                key={`clause-type-${index}`}
                label={clause.clause_name}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleClauseTypeDelete(clause.clause_name)}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {jurisdiction?.map((item: any, index: number) => (
              <Chip
                key={`jurisdiction-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleJurisdictionDelete(item)}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {contracting_parties?.map((contract: any, index: number) => (
              <Chip
                key={`contracting-parties-${index}`}
                label={contract}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleContractingPartiesDelete(contract)}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
          </Stack>
          <Stack
            sx={{
              ...scrollContainerSx,
              maxHeight: '80vh',
              paddingRight: '10px',
            }}
            gap={2}
          >
            {searchResultIds?.map((contractData: any, index: number) => (
              <Card
                key={index}
                sx={{ padding: '10px 15px', overflow: 'visible' }}
              >
                <Stack gap={1}>
                  <Stack
                    sx={{ color: '#996C84', alignItems: 'center' }}
                    direction="row"
                  >
                    <Typography variant="h6">
                      {contractData.file_name}
                    </Typography>
                    <IconButton
                      sx={{ color: '#996C84' }}
                      onClick={() =>
                        window.open(
                          '/document/documentlibrary/' +
                            btoa(contractData?.id?.toString()),
                          '_blank'
                        )
                      }
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Stack>
                  {Array.isArray(contractData.contract_type) ? (
                    <Typography sx={{ color: '#6D6E71', fontSize: '14px' }}>
                      {contractData.contract_type[0]}
                    </Typography>
                  ) : null}
                  {contractData.groups.length > 0 && (
                    <Stack direction="row" gap={1}>
                      {contractData.groups.map(
                        (item: string, index: number) => (
                          <Chip
                            key={`contract-group-${index}`}
                            label={item}
                            color="primary"
                            sx={{ fontSize: '13px' }}
                          />
                        )
                      )}
                      {contractData.projects.map(
                        (item: string, index: number) => (
                          <Chip
                            key={`contract-group-${index}`}
                            label={item}
                            color="success"
                            sx={{ fontSize: '13px' }}
                          />
                        )
                      )}
                    </Stack>
                  )}
                  <Stack
                    gap={1}
                    sx={{
                      ...scrollContainerSx,
                      maxHeight: '250px',
                    }}
                  >
                    {contractData.para.map((para: string, index: number) => (
                      <Typography
                        key={index}
                        dangerouslySetInnerHTML={{ __html: para }}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Card>
            ))}
          </Stack>
        </Stack>
      ) : (
        <Stack
          height="70vh"
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          <FilterBg />
          <Typography>{`${searchResultIds?.length} results found`}</Typography>
          <Typography>Start by searching/Filtering</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default Search;
