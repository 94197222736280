import React from 'react';

import {
  PaginationProps as MUIPaginationProps,
  Pagination,
} from '@mui/material';

interface ReusablePaginationProps extends MUIPaginationProps {
  count: number;
  page: number;
  onChange: (event: any, value: number) => void;
}

const ReusablePagination: React.FC<ReusablePaginationProps> = ({
  count,
  page,
  onChange,
  ...props
}) => {
  return (
    <Pagination count={count} page={page} onChange={onChange} {...props} />
  );
};

export default ReusablePagination;
