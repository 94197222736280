import axios from 'axios';

import { authApi } from './authApi';
import { getLocalStorage } from '../Authentication/Actions/authentication';

const APIPaths = {
  getSignatories:
    process.env.REACT_APP_RIVERUS_CLM_API + 'users/?role=Signatories',
  updateSignatories: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/',
  getPreSignUrl:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/aws/presigned-url/',
  getContractPreSignUrl:
    process.env.REACT_APP_RIVERUS_CLM_API + 'contracts/aws/presigned-url/',
};

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
  },
});

const signAPIConfig = () => ({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const authConfig = (contentType: string) => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`signAccessToken`)}`,
    'Content-Type': contentType,
  },
});

export const fetchSignatoriesList = async () => {
  const res = await fetch(`${APIPaths.getSignatories}`, APIConfig());
  return res.json();
};

export const fetchContractPreSignUrl = async (fileInfo: any) => {
  const response = await axios.post(
    APIPaths?.getContractPreSignUrl,
    { ...fileInfo },
    APIConfig()
  );
  return response;
};

export const fetchPresignedUrl = async (fileInfo: any) => {
  const response = await axios.post(
    APIPaths?.getPreSignUrl,
    { ...fileInfo },
    APIConfig()
  );
  return response;
};

export const updateSignatoriesList = (payload: any) => {
  const response = axios.patch(
    `${APIPaths?.updateSignatories}${payload.id}/`,
    payload.body,
    APIConfig()
  );
  return response;
};

export const updateWithSignRefreshToken = async (formData: FormData) => {
  return await axios
    .post(
      `https://api.in1.echosign.com/oauth/v2/refresh`,
      formData,
      signAPIConfig()
    )
    .then((response) => {
      return response;
    });
};

export const getAuthCredentials = async () => {
  const response = await axios.get(
    process.env.REACT_APP_RIVERUS_CLM_API + `adobe/auth/`,
    APIConfig()
  );
  return response;
};

export const getSignAccessToken = async (formData: FormData) => {
  const response = await axios.post(
    `https://api.in1.echosign.com/oauth/v2/token`,
    formData,
    signAPIConfig()
  );
  return response;
};

export const draftSignatures = async (draftId: string) => {
  const response = await axios.get(
    process.env.REACT_APP_RIVERUS_CLM_API + `stylus/signatures/${draftId}/`,
    APIConfig()
  );
  return response;
};

export const onSignedStatus = async (draftId: string, status: string) => {
  const response = await axios.patch(
    process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/${draftId}/`,
    {
      status: status,
    },
    APIConfig()
  );
  return response;
};

export const getSignatureDetails = async (
  draftId: string,
  agreementIdDetails: string
) => {
  const response = await axios.get(
    process.env.REACT_APP_RIVERUS_CLM_API +
      `stylus/draft/signature-details/?draft=${draftId}&agreement=${agreementIdDetails}`,
    APIConfig()
  );
  return response;
};

export const getDraft = async (draftUrl: string) => {
  return await fetch(draftUrl, {
    method: 'GET',
    mode: 'cors',
  })
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    });
};

export const uploadDocument = async (formData: FormData) => {
  const response = await axios.post(
    'https://api.in1.adobesign.com/api/rest/v6/transientDocuments',
    formData,
    authConfig('multipart/form-data')
  );
  return response;
};

export const sendAgreement = async (agreementInfo: any) => {
  const response = await axios.post(
    'https://api.in1.adobesign.com/api/rest/v6/agreements',
    agreementInfo,
    authConfig('application/json')
  );
  return response;
};

export const updateDraftSignatures = async (
  agreementId: string,
  draftId: string
) => {
  return await axios.post(
    process.env.REACT_APP_RIVERUS_CLM_API + `stylus/signatures/`,
    {
      agreement: agreementId,
      draft: draftId,
    },
    APIConfig()
  );
};

export const updateSignatureDetails = async (payload: any) => {
  const response = await axios.post(
    process.env.REACT_APP_RIVERUS_CLM_API +
      `stylus/signatures/create-signature-details/`,
    payload,
    APIConfig()
  );
  return response;
};

export const contractSupportDocs = async (payload: any) => {
  return await authApi.post('contracts/support-documents-contract/', payload);
};

export const reInitiateSignatureMail = async (payload: any) => {
  return await authApi.post('stylus/draft/re-initiate-email/', payload);
};

export const addTempSignatories = async (payload: any) => {
  return await authApi?.post('stylus/temp-signatories/', payload);
};

export const deleteTempSignatories = async (id: string) => {
  return await authApi.delete(`stylus/temp-signatories/${id}/`);
};

export const fetchTempSignatories = async (params = ''): Promise<any> => {
  return await authApi.get(`stylus/temp-signatories/${params}`);
};
